import React from 'react'
import ReactDOM from 'react-dom'
import VisReporter from './common/VisibilityReporter'

import Layout from './Layout/Layout'
import sections from './Sections'

ReactDOM.render(
  <React.StrictMode>
    <Layout sections={sections} />
  </React.StrictMode>,
  document.getElementById('root')
)

VisReporter.init('section')
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
