import './ProblemPitch.scss'

import { ReactComponent as RoleTenure } from '../../assets/role-tenure.svg'
import { ReactComponent as MultiTools } from '../../assets/multi-tools.svg'
import { ReactComponent as ExpCalendar } from '../../assets/expcalendar.svg'

import HeadlineTop from '../../common/HeadlineTop/HeadlineTop'

const list = [
  {
    title: 'In SMBs, contracts outlast most of the employees that created them.',
    text: (
      <div>
        <p>In most SMBs, legal agreements remain legally binding long after the employees that created them left the company.</p>
        <p>Ultimately the company is legally liable for all contractual obligations and rights.</p>
        <p>If only the company had access to all terms it had agreed to.</p>
      </div>
    ),
    Image: RoleTenure,
  },
  {
    title: 'Users need multiple, separate apps to create just one contract.',
    text: (
      <div>
        <p>Attention, sweat, and labor are the magic that connects multiple single-point solutions.</p>
        <p>Each new tool added to the mix decreases the opportunity for data utilization and efficiency.</p>
        <p>
          As a result, digital contracting becomes a slow, expensive, error-prone, laborious process that becomes a bottleneck for all
          operations.
        </p>
      </div>
    ),
    Image: MultiTools,
  },
  // {
  //   title: 'Simple, standard agreements take as long as large, bespoke deals.',
  //   text: (
  //     <div>
  //       <p>Rigorous, standard processes for all legal contracts are great. That's how typical, simple agreements like NDA take as long as large, important sales agreements.</p>
  //       <p>Standard contracts are preventing important deals finish faster. Large deals make simple agreements take a lot longer to complete.</p>
  //       <p>Revenue delayed. Everyone is unhappy. It's just how we do things here.</p>
  //     </div>
  //   ),
  //   Image: RoleTenure,
  // },
  {
    title: 'Never know exactly when your contracts expire.',
    text: (
      <div>
        <p>Once agreed, contracts turn into PDF files and get stored in a shared drive.</p>
        <p>Agreements' expiration dates may be tracked manually in the team calendar.</p>
        <p>
          Additional salient business terms beyond expiration date are hard to find, track and manage. 
          Contract analysis and term extraction from PDF file is slow and painful.
        </p>
      </div>
    ),
    Image: ExpCalendar,
  },
]

const Features = () => {
  return (
    <section className="problemPitch" id="pp">
      <HeadlineTop>Contracts, the mission-critical pillar everyone hates</HeadlineTop>
      <article>
        <ul>
          {list.map((item, key) => (
            <li key={key}>
              <div className="problemPitch__item">
                <h3>{item.title}</h3>
                {item.text}
              </div>
              <div className="problemPitch__image">{<item.Image />}</div>
            </li>
          ))}
        </ul>
      </article>
    </section>
  )
}

export default Features
