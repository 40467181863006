const Icon = (props) => (
<svg version="1.1"
	xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" {...props}>
	<g>
		<path d="M128,85.333c14.114,0,25.6-11.486,25.6-25.6V25.6C153.6,11.486,142.114,0,128,0s-25.6,11.486-25.6,25.6v34.133
				C102.4,73.847,113.886,85.333,128,85.333z M119.467,25.6c0-4.702,3.831-8.533,8.533-8.533s8.533,3.831,8.533,8.533v34.133
				c0,4.702-3.831,8.533-8.533,8.533s-8.533-3.831-8.533-8.533V25.6z"/>
		<path d="M8.533,85.333c4.71,0,8.533-3.823,8.533-8.533V59.733c0-12.442,13.158-25.6,25.6-25.6H76.8
				c4.71,0,8.533-3.823,8.533-8.533c0-4.71-3.823-8.533-8.533-8.533H42.667C20.736,17.067,0,37.803,0,59.733V76.8
				C0,81.51,3.823,85.333,8.533,85.333z"/>
		<path d="M324.267,494.933h-281.6c-12.442,0-25.6-13.158-25.6-25.6V119.467h452.267c4.71,0,8.533-3.823,8.533-8.533
				s-3.823-8.533-8.533-8.533H8.533c-4.71,0-8.533,3.823-8.533,8.533v358.4C0,491.264,20.736,512,42.667,512h281.6
				c4.71,0,8.533-3.823,8.533-8.533S328.977,494.933,324.267,494.933z"/>
		<path d="M469.333,315.733c4.71,0,8.533-3.823,8.533-8.533V145.067c0-4.71-3.823-8.533-8.533-8.533H42.667
				c-4.71,0-8.533,3.823-8.533,8.533v298.667c0,18.825,15.309,34.133,34.133,34.133H307.2c4.71,0,8.533-3.823,8.533-8.533
				s-3.823-8.533-8.533-8.533H68.267c-9.412,0-17.067-7.654-17.067-17.067V153.6h409.6v153.6
				C460.8,311.91,464.623,315.733,469.333,315.733z"/>
		<path d="M469.333,17.067H435.2c-4.71,0-8.533,3.823-8.533,8.533c0,4.71,3.823,8.533,8.533,8.533h34.133
				c12.442,0,25.6,13.158,25.6,25.6v264.533c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533V59.733
				C512,37.803,491.264,17.067,469.333,17.067z"/>
		<path d="M298.667,85.333c14.114,0,25.6-11.486,25.6-25.6V25.6c0-14.114-11.486-25.6-25.6-25.6s-25.6,11.486-25.6,25.6v34.133
				C273.067,73.847,284.553,85.333,298.667,85.333z M290.133,25.6c0-4.702,3.831-8.533,8.533-8.533s8.533,3.831,8.533,8.533v34.133
				c0,4.702-3.831,8.533-8.533,8.533s-8.533-3.831-8.533-8.533V25.6z"/>
		<path d="M238.933,196.267c0-4.71-3.823-8.533-8.533-8.533h-34.133c-4.71,0-8.533,3.823-8.533,8.533V230.4
				c0,4.71,3.823,8.533,8.533,8.533H230.4c4.71,0,8.533-3.823,8.533-8.533V196.267z M221.867,221.867H204.8V204.8h17.067V221.867z" />
		<path d="M384,85.333c14.114,0,25.6-11.486,25.6-25.6V25.6C409.6,11.486,398.114,0,384,0s-25.6,11.486-25.6,25.6v34.133
				C358.4,73.847,369.886,85.333,384,85.333z M375.467,25.6c0-4.702,3.831-8.533,8.533-8.533c4.702,0,8.533,3.831,8.533,8.533
				v34.133c0,4.702-3.831,8.533-8.533,8.533c-4.702,0-8.533-3.831-8.533-8.533V25.6z"/>
		<path d="M213.333,85.333c14.114,0,25.6-11.486,25.6-25.6V25.6c0-14.114-11.486-25.6-25.6-25.6c-14.114,0-25.6,11.486-25.6,25.6
				v34.133C187.733,73.847,199.219,85.333,213.333,85.333z M204.8,25.6c0-4.702,3.831-8.533,8.533-8.533
				c4.702,0,8.533,3.831,8.533,8.533v34.133c0,4.702-3.831,8.533-8.533,8.533c-4.702,0-8.533-3.831-8.533-8.533V25.6z"/>
		<path d="M418.133,324.267c-51.755,0-93.867,42.112-93.867,93.867S366.379,512,418.133,512S512,469.888,512,418.133
				S469.888,324.267,418.133,324.267z M418.133,494.933c-42.342,0-76.8-34.458-76.8-76.8s34.458-76.8,76.8-76.8
				s76.8,34.458,76.8,76.8S460.476,494.933,418.133,494.933z"/>
		<path d="M324.267,196.267c0-4.71-3.823-8.533-8.533-8.533H281.6c-4.71,0-8.533,3.823-8.533,8.533V230.4
				c0,4.71,3.823,8.533,8.533,8.533h34.133c4.71,0,8.533-3.823,8.533-8.533V196.267z M307.2,221.867h-17.067V204.8H307.2V221.867z" />
		<path d="M238.933,281.6c0-4.71-3.823-8.533-8.533-8.533h-34.133c-4.71,0-8.533,3.823-8.533,8.533v34.133
				c0,4.71,3.823,8.533,8.533,8.533H230.4c4.71,0,8.533-3.823,8.533-8.533V281.6z M221.867,307.2H204.8v-17.067h17.067V307.2z"/>
		<path d="M145.067,358.4h-34.133c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533h34.133
				c4.71,0,8.533-3.823,8.533-8.533v-34.133C153.6,362.223,149.777,358.4,145.067,358.4z M136.533,392.533h-17.067v-17.067h17.067
				V392.533z"/>
		<path d="M230.4,358.4h-34.133c-4.71,0-8.533,3.823-8.533,8.533v34.133c0,4.71,3.823,8.533,8.533,8.533H230.4
				c4.71,0,8.533-3.823,8.533-8.533v-34.133C238.933,362.223,235.11,358.4,230.4,358.4z M221.867,392.533H204.8v-17.067h17.067
				V392.533z"/>
		<path d="M153.6,281.6c0-4.71-3.823-8.533-8.533-8.533h-34.133c-4.71,0-8.533,3.823-8.533,8.533v34.133
				c0,4.71,3.823,8.533,8.533,8.533h34.133c4.71,0,8.533-3.823,8.533-8.533V281.6z M136.533,307.2h-17.067v-17.067h17.067V307.2z"/>
		<path d="M426.667,409.6V384c0-4.71-3.823-8.533-8.533-8.533S409.6,379.29,409.6,384v34.133c0,4.71,3.823,8.533,8.533,8.533h51.2
				c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533H426.667z"/>
		<path d="M409.6,196.267c0-4.71-3.823-8.533-8.533-8.533h-34.133c-4.71,0-8.533,3.823-8.533,8.533V230.4
				c0,4.71,3.823,8.533,8.533,8.533h34.133c4.71,0,8.533-3.823,8.533-8.533V196.267z M392.533,221.867h-17.067V204.8h17.067V221.867
				z"/>
		<path d="M401.067,307.2c4.71,0,8.533-3.823,8.533-8.533V281.6c0-4.71-3.823-8.533-8.533-8.533h-34.133
				c-4.71,0-8.533,3.823-8.533,8.533v17.067c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533v-8.533h17.067v8.533
				C392.533,303.377,396.356,307.2,401.067,307.2z"/>
		<path d="M298.667,375.467c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533H281.6c-4.71,0-8.533,3.823-8.533,8.533
				v34.133c0,4.71,3.823,8.533,8.533,8.533h17.067c4.71,0,8.533-3.823,8.533-8.533s-3.823-8.533-8.533-8.533h-8.533v-17.067H298.667
				z"/>
		<path d="M153.6,196.267c0-4.71-3.823-8.533-8.533-8.533h-34.133c-4.71,0-8.533,3.823-8.533,8.533V230.4
				c0,4.71,3.823,8.533,8.533,8.533h34.133c4.71,0,8.533-3.823,8.533-8.533V196.267z M136.533,221.867h-17.067V204.8h17.067V221.867
				z"/>
		<path d="M324.267,315.733V281.6c0-4.71-3.823-8.533-8.533-8.533H281.6c-4.71,0-8.533,3.823-8.533,8.533v34.133
				c0,4.71,3.823,8.533,8.533,8.533h34.133C320.444,324.267,324.267,320.444,324.267,315.733z M307.2,307.2h-17.067v-17.067H307.2
				V307.2z"/>
	</g>
</svg>
)

export default Icon
