import './Logos.scss'

const Logos = () => {
  const list = [
    {
      name: 'HeroicLabs',
      logoUrl: 'logos/heroiclabs.svg',
    },
    {
      name: 'Kenubi Software',
      logoUrl: 'logos/kenubi.png',
    },
    {
      name: 'Algomo',
      logoUrl: 'logos/algomo.png',
    },
    {
      name: 'Boxifier',
      logoUrl: 'logos/boxifier.png',
    },
    {
      name: 'Broadn',
      logoUrl: 'logos/broadn.png',
    },
    {
      name: 'Guardian Inheritance',
      logoUrl: 'logos/GuardianInheritance.jpg',
    },
    {
      name: 'Acquisition Assistant',
      caption:<div className='Logos__item__caption'>Acquisition Assistant</div>,
      logoUrl: 'logos/ACQ.png',
    },   
  ]

  const quotes = [
    {
      name: 'Calin Drimbau',
      title: 'CEO',
      quote:
        '“Great product with a nice design and a well-thought process to follow. I\'m so glad we avoid all the tedious manual work!”',
      logoUrl: 'logos/broadn.png',
    },{
      name: 'Razvan Hobeanu',
      title: 'CEO',
      quote:
        [<p key="1">“Great usability and design. It simplifies our contract process a lot and makes it easy for everyone in our team to send a contract for signing.</p>,
        <p key="2">My team can now <strong>send an NDA in under 5 minutes</strong>!”</p>],
      logoUrl: 'logos/boxifier.png',
    },
    // {
    //   name: 'Charis Sfyrakis',
    //   title: 'CEO',
    //   quote:
    //     '“YumiDocs is a contract management solution that is easy to understand and helps us grow.”',
    //   logoUrl: 'logos/algomo.png',
    // },
  ]

  return (
    <section className="Logos" id="Logos">
      <article className="Logos__quotes">
        {quotes.map((q, key) => (
          <div key={key} className="Logos__quotes__card">
            <div className='quote'>{q.quote}</div>
            <div className="source">
              <div className="who">
                <div className="name">{q.name}</div>
                <div  className="title">{q.title || ''}</div>
              </div>
              <div className="imageContainer">
                <img src={q.logoUrl} alt="Quote logo"/>
              </div>
            </div>
          </div>
        ))}
      </article>
      <article className="Logos__content">
        {list.map((item, key) => (
          <div key={key} className="Logos__item">
            <img src={item.logoUrl} alt="logo"/>
            {item.caption?item.caption:''}
          </div>
        ))}
      </article>
    </section>
  )
}

export default Logos
