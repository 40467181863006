import { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import Segment from 'common/SegmentIO'
import './Layout.scss'
import './Layout.section.scss'

const ScrollToTop = ({ sections }) => {
  const loc = useLocation()

  const { pathname, hash } = loc

  useEffect(
    () => {
      if (hash) {
        const section = document.querySelector(hash)
        if (section && section.tagName === 'SECTION') {
          section.scrollIntoView()
        } else {
          window.scrollTo(0, 0)
        }
      } else {
        window.scrollTo(0, 0)
      }
      const sect = sections.find((item) => item.path === pathname) || { label: '-- not correct url --' }

      Segment.getAnalytics().then((ana) => ana.track(sect.label, { title: sect.id }))
    },
    [ pathname, hash, sections ]
  )

  return null
}

const Layout = ({ sections = [] }) => {
  return (
    <Router>
      <ScrollToTop sections={sections} />
      <div className="layout">
        <Header />

        <div className="layout__content">
          <Switch>
            {sections.map(({ path, children }, key) => (
              <Route key={key} exact path={path}>
                {children.map((Section, k) => <Section key={k} />)}
              </Route>
            ))}
            <Route>{sections[0].children.map((Section, k) => <Section key={k} />)}</Route>
          </Switch>
          <Footer />
        </div>
      </div>
    </Router>
  )
}

export default Layout
