const Icon = (props) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.0002 25.7273C5.97114 25.7273 0.272949 20.0291 0.272949 13C0.272949 5.97091 5.97114 0.27272 13.0002 0.27272C20.0293 0.27272 25.7275 5.97091 25.7275 13C25.7115 20.0224 20.0227 25.7113 13.0002 25.7273ZM13.0002 2.45454C7.17613 2.45454 2.45477 7.1759 2.45477 13C2.45477 18.8241 7.17613 23.5454 13.0002 23.5454C18.8243 23.5454 23.5457 18.8241 23.5457 13C23.5377 7.17922 18.821 2.46255 13.0002 2.45454ZM10.0911 17.7127C10.3085 17.9338 10.6107 18.0504 10.9202 18.0327C11.2108 18.0393 11.4906 17.9231 11.6911 17.7127L19.5311 9.7709C19.9565 9.34499 19.9565 8.655 19.5311 8.22908C19.3285 8.02116 19.0505 7.90389 18.7602 7.90389C18.4699 7.90389 18.1919 8.02116 17.9893 8.22908L10.862 15.3418L7.95295 12.4327C7.52312 12.0322 6.8533 12.044 6.43786 12.4595C6.02243 12.8749 6.01061 13.5447 6.41113 13.9745L10.0911 17.7127Z"
      />
    </svg>
  )
}

export default Icon
