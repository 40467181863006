import './Features2.scss'

import { ReactComponent as Feature1 } from '../../assets/feature1.svg'
import { ReactComponent as Feature2 } from '../../assets/feature2.svg'
import { ReactComponent as Feature3 } from '../../assets/feature3.svg'
import { ReactComponent as Feature4 } from '../../assets/feature4.svg'
import { ReactComponent as Feature5 } from '../../assets/feature5.svg'
import { ReactComponent as Feature6 } from '../../assets/feature6.svg'

import HeadlineTop from '../../common/HeadlineTop/HeadlineTop'

const list = [
  {
    title: 'Template Management',
    text:
      'Set reusable structure in place to enhance automation and decision making. Templates help you generate individual contracts in minutes.',
    Image: Feature1,
  },
  {
    title: 'Contract generation',
    text: 'Fill in your elections with salient data. Start from safe and approved past terms, clauses, or positions. Retain all metadata.',
    Image: Feature2,
  },
  {
    title: 'Negotiate',
    text:
      'Collaborate with your legal team. Compare your counterparty against your precedents. See what’s changed in seconds. Workflow-driven methodology to avoid mistakes and speed up negotiation time. Keep track of all revisions and changes.',
    Image: Feature3,
  },
  {
    title: 'Fast execution',
    text: 'Execute contracts using electronic signatures. When the document gets executed, you retain all the data next to your contract.',
    Image: Feature4,
  },
  {
    title: 'Manage',
    text:
      'Manage your risk and exposure. See all your terms, parties, and clauses in a dashboard. Business, operational, and legal analytics in one place.',
    Image: Feature5,
  },
  {
    title: 'Retouch',
    text: 'Amend or renew contracts starting from the latest terms and position. Terminate ongoing contracts.',
    Image: Feature6,
  },
]

const Features = () => {
  return (
    <section className="Features2" id="Features">
      <HeadlineTop type="3">Features</HeadlineTop>

      <article>
        <ul>
          {list.map((item, key) => (
            <li key={key}>
              <div className="Features2__item">
                <h2>{item.title}</h2>
                <p>{item.text}</p>
              </div>
              <div className="Features2__image">{<item.Image />}</div>
            </li>
          ))}
        </ul>
      </article>
    </section>
  )
}

export default Features
