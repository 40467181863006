import './WhatPainsWeSolve.scss'

import HeadlineTop from '../../common/HeadlineTop/HeadlineTop'

const list = [
  {
    id: 'Pipeline',
    title: 'Control the contract pipeline',
    text: (
      <div>
        <p>Turn the ad-hoc contracting work into a methodological process that shortens the wait time and increases visibility.</p>
        <div className="two">
          <h5>Dynamic board</h5>
          <p>Contract status automatically changes based on all parties' actions and the latest content updates.</p>
        </div>
        <div className="three">
          <h5>Collaborative</h5>
          <p>Your team's input reflects immediately on the pipeline board. </p>
        </div>
      </div>
    ),
    Image: <img src="images/Pipeline.png" alt=''/>,
  },
  {
    id: 'Templates',
    title: 'Centralized templates and playbook hub',
    text: (
      <div>
        <p>
          Ensure your team always uses the latest contract version templates. Apply the commercial and legal terms consistently across all
          your client types.
        </p>
        <div className="two">
          <h5>Ready to use playbooks</h5>
          <p>
            Start each contract from known and approved positions for each client class or scenario. Contracts with already approved and
            known terms can be streamlined and scaled at the team level.
          </p>
        </div>
      </div>
    ),
    Image: <img src="images/Templates.png" alt='' />,
  },
  {
    id: 'Repository',
    title: 'One repository',
    text: (
      <div>
        <p>All contracts are stored in one central repository available to the entire team based on permissions and need to see rights.</p>
        <div className="two">
          <h5>Analyze</h5>
          <p>Filter contracts based on terms, figures, and expiration dates. Never miss a renewal term again.</p>
        </div>
      </div>
    ),

    Image: <img src="images/Filter.png" alt='' />,
  },
]

const WhatPainsWeSolve = () => {
  return (
    <section className="WhatPainsWeSolve" id="WhatPainsWeSolve">
      <HeadlineTop>Manage better contracts, faster</HeadlineTop>

      <article>
        <ul>
          {list.map((item, key) => (
            <li key={key} className={item.id}>
              <div className="WhatPainsWeSolve__item">
                <h4>{item.title}</h4>
                {item.text}
              </div>
              <div className="WhatPainsWeSolve__image">{item.Image}</div>
            </li>
          ))}
        </ul>
      </article>
    </section>
  )
}

export default WhatPainsWeSolve
