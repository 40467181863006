import Workflow from '../../Sections/Workflow/Workflow'
import OurProposal from '../../Sections/OurProposal/OurProposal'
import ContractsAsData from '../../Sections/ContractsAsData/ContractsAsData'
import GetEfficiency from '../../Sections/GetEfficiency/GetEfficiency'
import TemplateLibrary from '../../Sections/TemplateLibrary/TemplateLibrary'
import WhatPainsWeSolve from '../../Sections/WhatPainsWeSolve/WhatPainsWeSolve'
import Principles from '../../Sections/Principles/Principles'

const PlatformPage = () => {
  return [
    <Workflow key="wf" />,
    <OurProposal key="op" />,
    <WhatPainsWeSolve key="wpws" />,
    <TemplateLibrary key="tl" />,
    <ContractsAsData key="cad" />,    
    <GetEfficiency key="gef" />,
    <Principles key="princ" />
  ]
}

export default PlatformPage
