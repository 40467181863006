import './HeadlineTop.scss'

const Headline = ({ children, type }) => {
  return (
    <div className="HeadlineTop">
      <div className="TopHighlight" />
      <div>{children}</div>
    </div>
  )
}

export default Headline
