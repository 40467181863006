import './GetEfficiency.scss'
import HeadlineTop from '../../common/HeadlineTop/HeadlineTop'
import TickIcon from '../../Icons/Tick'
import Segment from 'common/SegmentIO'

const GetEfficiency = () => {
  const list = [
    {
      title: 'For Start-ups',
      text: ['Hire or fire FTEs', 'Freelance and contractor agreements', 'Start selling', <strong>Bring your own templates</strong>],
    },
    {
      title: 'For Sales teams',
      text: [
        'Master service agreements',
        'NDAs',
        'Proposals',
        'Statement of work',
        'Service level agreements',
        'Purchase order forms',
        <strong>Bring your own templates</strong>,
      ],
    },
    {
      title: 'For SMEs',
      text: [
        'Lease agreements',
        'Procurement',
        'Service agreements',
        'Consultancy agreements',
        'Loan agreements',
        'Shareholders agreement',
        <strong>Bring your own templates</strong>,
      ],
    },
  ]

  return (
    <section className="GetEfficiency" id="GetEfficiency">
      <HeadlineTop>Get efficiency</HeadlineTop>
      <article className="GetEfficiency__content">
        {list.map((item, key) => (
          <div key={key} className="GetEfficiency__item">
            <h4>{item.title}</h4>
            <ul>
              {item.text.map((text, k) => (
                <li key={k}>
                  <TickIcon /> {text}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </article>
      <div className="ContentAware__actions">
        <a
          href="https://app.yumidocs.com"
          target="_blank"
          className="btn dark"
          rel="noopener noreferrer"
          onClick={() => {
            Segment.getAnalytics().then((ana) => ana.track('Start Now', { section: 'GetEfficiency' }))
          }}
        >
          Start now
        </a>
      </div>
    </section>
  )
}

export default GetEfficiency
