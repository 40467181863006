const Icon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 511 511" {...props}>
    <g>
      <path d="M404.9,191.8c22.9,0,44.4,0,65.9,0c20.9,0,31.7,10.8,31.7,31.9c0,77.9,0,155.8,0,233.8 c0,21.4-10.9,32.3-32.1,32.3c-145.8,0-291.6,0-437.4,0c-20.9,0-31.7-10.9-31.7-32c0-78.1,0-156.3,0-234.4c0-21,10.7-31.6,31.9-31.6 c100.4,0,200.8,0,301.2,0.1c4.6,0,7.5-1.4,10.3-5.1C384.1,134.5,423.7,82.2,463.4,30c8.4-11.1,10.2-11.3,21.4-2.8 c5.8,4.4,11.6,8.8,17.3,13.2c9.5,7.3,9.9,9.9,2.8,19.2c-31.7,41.9-63.5,83.7-95.2,125.6C408.4,187,407.1,188.8,404.9,191.8z M327.3,209.4c-2.3-0.3-3.3-0.5-4.2-0.5c-89,0-178,0-266.9,0c-8.9,0-12.4,4-12.4,13.4c0,79,0,157.9,0,236.9 c0,9.8,3.7,13.5,13.6,13.5c129.6,0,259.3,0,388.9,0c10.3,0,13.8-3.5,13.8-13.8c0-78.7,0-157.5,0-236.2c0-10.2-3.5-13.6-14-13.7 c-16.2-0.1-32.4,0.1-48.5-0.1c-4.2-0.1-6.8,1.5-9.2,4.8c-18.4,24.6-37,49.1-55.7,73.4c-2.1,2.8-5.4,5.6-8.6,6.3 c-7.4,1.8-15.1,2.5-22.7,3.3c-5.6,0.6-9.1-2.1-10-7.8c-0.9-5.7-1.1-11.6-2.5-17.2c-1.7-6.8,0.3-11.8,4.4-17.1 C304.5,239.9,315.4,225.1,327.3,209.4z M457.4,66.2c-1.6,2-2.9,3.6-4.1,5.2c-47.7,62.8-95.2,125.8-143.1,188.4 c-4.7,6.1-5.2,11.5-2.8,18.1c7.3,0.9,12.3-1.6,16.9-7.7c37.1-49.5,74.6-98.7,111.9-148c11.5-15.2,23-30.4,34.7-45.8 C466.3,73,462.2,69.9,457.4,66.2z M27.3,209.5c-7.1,1.6-9.3,4.6-9.3,13.5c0,78.5,0,157,0,235.5c0,2.1,0.1,4.2,0.7,6.2 c1.3,4.6,4.3,7.1,8.6,7.5C27.3,384.5,27.3,297.2,27.3,209.5z M476.4,472c6.7-1.3,9.2-4.5,9.2-12.6c0-79.2,0-158.4,0-237.5 c0-2-0.3-4.2-1-6.1c-1.5-3.8-4.1-6.3-8.2-6.4C476.4,297.3,476.4,384.7,476.4,472z M475.7,41.9c-2.9,3.8-5.4,7.1-8.1,10.7 c4.7,3.5,9,6.7,13.6,10.2c2.8-3.8,5.3-7.2,7.9-10.9c-3.5-2.7-6.4-5-9.4-7.2C478.5,43.9,477.3,43.1,475.7,41.9z" />
      <path d="M205.2,68.5c-0.1-16.5,12.9-29.8,29.2-30c16.7-0.2,30,13.1,30,29.8c0,16.4-13.2,29.7-29.5,29.8 C218.7,98.2,205.3,84.9,205.2,68.5z M234.9,55.7c-7,0-12.8,5.6-12.9,12.5c-0.1,6.8,5.8,12.7,12.7,12.8c6.9,0,12.8-5.6,12.8-12.6 C247.7,61.5,242,55.8,234.9,55.7z" />
      <path d="M115.9,149c-10.8,9.3-14.3,10.4-18.6,6.3c-4.5-4.3-3.3-8,6.1-18.8c-3.7-5.7-14.3-10.4-5.8-18.7 c7.8-7.6,12.5,2,18.1,6c5.9-3.5,10.7-14.3,18.9-5.6c7.5,8-2.4,12.4-6.2,18.2c3.6,5.7,14.3,10.5,5.7,18.7 C126.3,162.6,121.6,153.1,115.9,149z" />
      <path d="M137.1,63.8c0.2,4.6-3.4,8.4-8,8.6c-4.8,0.2-9-3.6-9-8.3c0-4.5,3.8-8.2,8.4-8.3 C133.2,55.7,136.9,59.2,137.1,63.8z" />
      <path d="M140.4,320.9c-3.8-25.6-7.4-50-11-74.5c-1-6.9,1.4-11.1,6.7-12c5.1-0.8,8.9,2.6,9.9,9.4c4,26,7.9,52.1,12,78.1 c0.3,1.8,1.5,4.6,2.6,4.8c12.6,2.1,25.3,5.4,37.9,5.1c14.9-0.3,22-9.1,23-24.2c0.4-6.4-0.2-12.9-0.3-19.3c-0.1-2.1-0.5-4.2-0.3-6.2 c0.4-5.1,1-10.2,7.2-11.7c5.7-1.4,9.8,0.6,12.3,7.7c5,14.1,9.2,28.4,13.8,42.6c1,3.1,2.4,6.1,3.9,9.9c2.8-5.6,4.8-10.5,7.6-15 c7.7-12.3,19.7-13.3,29.3-2.5c2.9,3.2,5.5,6.8,7.8,10.4c10.7,17.1,26.7,22.4,45.6,19.7c10.8-1.5,21.4-4.6,32.1-7 c1.8-0.4,3.5-1.2,5.4-1.5c4.8-0.8,8.2,1.3,9.7,5.8c1.4,4.4-0.3,8.3-4.4,9.8c-23,8.2-46.4,14.4-71.1,7.6 c-12.8-3.5-22.1-11.9-29.6-22.5c-2.9-4-5.7-8-9.5-13.1c-2.7,5.9-4.8,10.5-7,15.1c-3.1,6.7-6.2,13.7-15,13.9 c-9,0.2-12.8-6.7-16.1-13.5c-2.4-4.8-4.3-9.9-6.5-15.1c-16.7,34.3-45.7,27.7-74.8,22.5c0.7,7.9,1.4,15.4,2.1,23 c0.3,3.1,0.5,6.2,0.5,9.3c-0.2,21.6-14.6,32.8-35.5,26.8c-20.1-5.7-34.2-19.4-44.3-36.9c-4.7-8-8.2-17.5-9.3-26.6 c-2-17.2,9-28.5,26.3-27.6c10.2,0.5,20.3,3.5,30.5,5.5C134.4,319.3,137,320,140.4,320.9z M147.1,374.5c0.2,0,0.4,0,0.6-0.1 c-1.3-10.3-2.4-20.6-3.9-30.9c-0.2-1.6-1.4-3.9-2.6-4.2c-12.8-3.2-25.6-6.9-38.6-8.8c-9.3-1.4-12.4,2.9-10.4,12.5 c3.1,14.5,11.6,25.7,22.9,34.6c6,4.7,13.3,8.4,20.6,10.8c7.1,2.4,10.9-1.3,11.4-8.9C147.2,377.8,147.1,376.2,147.1,374.5z" />
      <path d="M315,438.7c-5,0-10,0.1-14.9,0c-5.2-0.2-8.7-2.8-9-8.3c-0.3-5,3.3-8.6,9.2-8.8c9.9-0.2,19.9-0.2,29.8,0 c5.9,0.1,9.4,3.6,9.4,8.6c-0.1,5-3.6,8.3-9.5,8.5C324.9,438.9,319.9,438.7,315,438.7z" />
      <path d="M188.4,438.7c-5,0-10,0.2-14.9,0c-5.9-0.2-9.4-3.7-9.1-8.8c0.3-5.6,3.8-8.2,9-8.2c10.2-0.2,20.3-0.2,30.5,0 c5.3,0.1,8.8,3.1,8.8,8.5c0,5.4-3.5,8.3-8.8,8.6C198.7,438.9,193.6,438.7,188.4,438.7z" />
      <path d="M251.6,438.7c-4.8,0-9.5,0.1-14.3,0c-6-0.2-9.5-3.3-9.6-8.4c-0.1-5.1,3.3-8.6,9.2-8.7c9.9-0.2,19.9-0.2,29.8,0 c5.8,0.1,9.5,3.7,9.4,8.7c-0.1,4.9-3.8,8.2-9.7,8.4C261.5,438.9,256.5,438.7,251.6,438.7z" />
      <path d="M378.9,421.6c5,0,10-0.2,14.9,0c5.2,0.2,8.7,2.9,8.8,8.4c0,5.6-3.4,8.5-8.6,8.6c-10.1,0.3-20.3,0.2-30.5,0 c-5.8-0.1-9.6-3.8-9.2-8.8c0.4-5.4,3.8-8.2,9.1-8.3C368.5,421.4,373.7,421.6,378.9,421.6z" />
      <path d="M125.4,421.6c4.8,0,9.5-0.1,14.3,0c5.9,0.2,9.6,3.5,9.7,8.4c0.1,4.8-3.7,8.6-9.3,8.7c-9.9,0.2-19.9,0.2-29.8,0 c-5.9-0.1-9.4-3.6-9.3-8.7c0.1-5,3.6-8.1,9.5-8.5c0.2,0,0.4,0,0.6,0C115.9,421.6,120.7,421.6,125.4,421.6z" />
      <path d="M434.6,438.7c-2.9,0-5.8,0.2-8.7,0c-5-0.5-8.2-3.4-8.3-8.4c-0.1-5.1,3.1-8.2,8.1-8.5c5.8-0.4,11.6-0.4,17.4,0 c5,0.3,8.2,3.2,8.2,8.4c0.1,5.2-3.1,8-8,8.6C440.5,438.9,437.5,438.7,434.6,438.7z" />
      <path d="M68.8,438.7c-2.9,0-5.8,0.3-8.7-0.1c-4.9-0.7-7.9-3.5-7.8-8.8c0.1-5,3.2-7.8,7.8-8.1c6-0.4,12-0.4,18,0 c4.6,0.3,7.7,3.3,7.9,8.1c0.2,5.1-2.9,8.1-7.9,8.8C75.1,439,71.9,438.7,68.8,438.7z" />
    </g>
  </svg>
)

export default Icon
