import { Link } from 'react-router-dom'

import Segment from '../../common/SegmentIO'
import TwitterIcon from '../../Icons/Twitter'
import LinkedinIcon from '../../Icons/Linkedin'

import './Footer.scss'

const Footer = () => {
  return (
    <footer className="Footer">
      <nav>
        <ul>
          <li>
            <Link to="/#ContentAware">Home</Link>
          </li>

          <li>
            <Link to="/platform">Platform</Link>
          </li>

          <li>
            <Link to="/pricing">Pricing</Link>
          </li>

          <li>
            <a
              className="menuItem"
              target="_blank"
              rel="noopener noreferrer"
              href="https://yumidocs.medium.com/"
              onClick={() => Segment.getAnalytics().then((ana) => ana.track('News', { section: 'Footer', title: 'News' }))}
            >
              News
            </a>
          </li>
          <li>
            <Link to="/#TemplateLibrary">Template Library</Link>
          </li>

          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/terms-of-service">Terms of Service</Link>
          </li>
          <li>
            <Link to="/security">Security</Link>
          </li>
        </ul>
      </nav>

      <div className="allrights">
        <div className="social">
          <span className="channel">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/YumiDocs"
              onClick={() => Segment.getAnalytics().then((ana) => ana.track('Twitter', { section: 'Footer', title: 'Twitter' }))}
            >
              <TwitterIcon className="logoIcon" height="20px" />
            </a>
          </span>
          <span className="channel">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/yumidocs"
              onClick={() => Segment.getAnalytics().then((ana) => ana.track('Linkedin', { section: 'Footer', title: 'Linkedin' }))}
            >
              <LinkedinIcon className="logoIcon" height="20px" />
            </a>
          </span>
        </div>

        <div>
          <span>© {new Date().getFullYear()} Yumi, Ltd. All rights reserved.</span>
        </div>
      </div>
      {/* <div className="Footer__logo">
        <LogoIcon />
      </div> */}
    </footer>
  )
}

export default Footer
