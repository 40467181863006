import './ToolsForGrowth.scss'

import { ReactComponent as LineSvg } from '../../assets/line.svg'

import HeadlineTop from '../../common/HeadlineTop/HeadlineTop'

const ToolsForGrowth = () => {
  const list = [
    {
      title: 'Self-service great contracts',
      text: 'Your teams get access to the latest version of your company approved templates. Standard terms, playbooks and precedents are available company-wide. Enforce standardization in contract drafting and the commercial terms your team sends out by design.',
    },
    
    {
      title: 'Central document repository',
      text: `You don't have to open a PDF file to know its content anymore. The entire contract portfolio is stored in a central location. You can search, filter, and sort all contracts based on key commercial and legal terms.`,
    },
    {
      title: 'Inspect key terms in real-time',
      text: `Get real-time visibility into all your contractual terms. Expiration, renewals, terms, and figures can get reported back to you in a real-time dashboard. You can see the entire portfolio without opening any files.`,
    },
    {
      title: 'Automate labor and repetitive tasks away',
      text: 'YumiDocs automates away all manual labor around file versioning, storage, and email management, chasing for updates, copy/pasting text across multiple applications and more. Our users can focus on the work that matters.',
    },
    {
      title: 'Collaborative contracting',
      text:
        'Bring your legal and internal teams to the live negotiation process. Contextualize each engagement with your lawyers so they can focus on the subject matter instead of re-formatting pages.',
    },
    {
      title: 'Empower your counterparties',
      text:
        'Empower your counterparties with the same digital tools you are using. The sooner they act, the faster you can move on.',
    },
    {
      title: 'Read only what’s changed',
      text:
        'Get automatic redlining and term comparisons for diverging points with your counterparties. Inspect historic revisions where you need them. That’s not in your inbox but side by side in your contract negotiation tool.',
    },
    {
      title: 'Rapid decision-making',
      text:
        'Substantiate your decisions with real-time updates from your digital negotiations and the interactions with your counterparties.',
    },

  ]

  return (
    <section className="ToolsForGrowth" id="ToolsForGrowth">
      <HeadlineTop>Tools for growth</HeadlineTop>

      <article>
        <div className="svg-line">
          <LineSvg />
        </div>
        <ul>
          {list.map((item, key) => (
            <li key={key}>
              <div className="ToolsForGrowth__item">
                <h2>{item.title}</h2>
                <p>{item.text}</p>
              </div>
            </li>
          ))}
        </ul>
      </article>
    </section>
  )
}

export default ToolsForGrowth
