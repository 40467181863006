import './CaseStudy1.scss'

const CaseStudyBoxifier = () => {
  return (
    <section className="CaseStudyBoxifier">
      <div className="CaseStudyBoxifier__content">
        <h2>Boxifier shortens the contracting process by up to 85%</h2>
        <br />
        <h3>
          YumiDocs transformed Boxifier’s manual and labor-intensive process to one that is guided by technology and automation, saving
          them valuable energy, time, and money.
        </h3>

        <table width="100%">
          <tbody>
            <tr className="headrow">
              <td />
              <td>Process type</td>
              <td>Avg. contract cycle</td>
            </tr>
            <tr>
              <td className="headcol">Before</td>
              <td>
                <span className="figure"> 100% </span>bespoke
              </td>
              <td>
                up to<span className="figure"> 13 weeks</span>
              </td>
            </tr>
            <tr className="linetop">
              <td className="headcol">With YumiDocs</td>
              <td>
                <span className="figure"> 82% </span>standard<br />
                <span className="figure"> 18% </span>bespoke
              </td>
              <td>
                <span className="figure">82% </span>of contracts in<span className="figure"> 8 days </span>or less<br />
                overall<span className="figure"> 85% </span>less time
              </td>
            </tr>
          </tbody>
        </table>
        <div className="company">
          <h2>About Boxifier</h2>
          <h2>
            <a href="https://boxifier.com" target="_blank" rel="noopener noreferrer">
              <img src="/logos/boxifier.png" alt="Boxifier logo" />
            </a>
          </h2>
        </div>
        <p>
          <strong>
            <a href="https://boxifier.com" target="_blank" rel="noopener noreferrer">Boxifier</a></strong> sells sync and backup solutions for cloud storage designed with performance and flexibility in mind. Boxifier clients purchase the
          software online and range from home users to medium and large clients.
        </p>
        <div className="quote">
          <p>
            <span className="figure">“</span>
            At one point, we faced concurrent demand due to a spike in the number of new clients, which led to a delay in the contract
            signing process.<br />
            <br />
            In order to move things faster, we reached out to YumiDocs team and decided to implement their solution. This really helped us
            save time and focus on more valuable aspects of our product and business.
            <span className="figure">”</span>
          </p>
          <div className="owner">Razvan Hobeanu, CEO Boxifier</div>
        </div>
        <h2>The challenge</h2>
        <p>
          Most of Boxifier's medium to large clients want extra flexibility with their solutions and additional services. For this reason,
          Boxifier runs a fully bespoke sales process.
        </p>
        <p>
          As the product developed and the client base grew, the sales process became time-consuming and cumbersome. The process expanded
          outside the scope of standard agreement templates and involved external legal counsel that drafted bespoke provisions for the NDAs
          and the Service Agreements that cover the client requests and package details.
        </p>
        <p>
          The contract is exchanged between the lawyer and the counterparty almost a dozen times. Clauses get richer and cover more nuances.
          The most negotiate provisions are indemnity, warranties, SLAs, support, and limitation of liability. Only after 4-18 weeks of
          phone calls and chaser emails does the contract get signed, the membership starts and Boxifier subsequently gets paid.
        </p>
        <p>
          On top of experiencing fatigue from this draining process, the additional revenue from the enterprise licenses was spent on legal
          fees. The time it took for the contract to be signed delayed revenue significantly and directly impacts the company's cash flow.
        </p>
        <h2>The solution</h2>
        <p>
          YumiDocs enabled Boxifier unprecedented speed in signing NDA’s, most being completed on the same day. With both parties
          simultaneously looking at the same contract and making changes, conversations about objections are faster and more transparent.
          YumiDocs changed how Boxifier handles its service agreements. Common bespoke change requests from clients were added as elective
          options to the sales template. This approach allows the team to have an improved control over the nuances and options provided to
          the prospects.
        </p>
        <p>
          The updated service agreement template was simplified to select what type of indemnity provision was preferred and the stringency
          of the SLA terms. E-Signing the contract is immediately available as soon as the counterparty agrees to the terms, further saving
          time when manually managing the process with alternative dedicated e-signature solutions.
        </p>
        <h2>The results</h2>
        <p>
          Overall, the contracting process is shortened by up to 85% by reducing manual steps, reusing data, and providing a unified digital
          experience to all contractual parties.
        </p>

        <ul>
          <li>With YumiDocs, more than 70% of the total new service agreements are completed in less than 8 working days.</li>
          <li>
            The template improves iteratively, with clauses becoming more defined with multiple options available to the counterparty.
          </li>
          <li>The automated part of the platform helps the team streamline their tasks through notifications, reminders, and updates.</li>
          <li>
            The status of each ongoing deal can be quickly checked from a centralized dashboard where contracts can be selected and filtered
            based on their on key contractual terms like expiration dates, party names, contract of indemnity provision type.
          </li>
        </ul>
      </div>
    </section>
  )
}

export default CaseStudyBoxifier
