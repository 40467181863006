import Segment from './SegmentIO'

const visibilityReporter = {
  init(tagName) {
    this.observer = null
    this.timers = {}
    this.documentInactiveAt = 0

    //tab switch detection
    document.addEventListener('visibilitychange', this.handleGlobalVisibilityChange, false)

    let targets = Array.from(document.querySelectorAll(tagName))

    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.22,
    }

    this.observer = new IntersectionObserver(this.manageVisibilityChanges, options)

    targets.forEach((t, index) => {
      if (!t.id) {
        t.id = index
        t.title = index
      }
      this.timers[t.id] = { isVisible: false, startedAt: 0, totalViewTime: 0, id: t.id, passes: 0, title: t.id }

      this.observer.observe(t)
    })
  },

  manageVisibilityChanges(entries) {
    entries.forEach(function(entry) {
      let el = entry.target
      let elState = visibilityReporter.timers[el.id]

      if (entry.isIntersecting) {
        if (!elState.isVisible) {
          elState.isVisible = true
          elState.startedAt = performance.now()
        }
      } else {
        if (elState.isVisible) {
          elState.isVisible = false
          elState.totalViewTime = elState.totalViewTime + performance.now() - elState.startedAt
          elState.passes = elState.passes + 1

          visibilityReporter.reportScroll(elState)
        }
      }
      visibilityReporter.timers[el.id] = elState
    })
  },

  handleGlobalVisibilityChange() {
    if (document.hidden) {
      visibilityReporter.documentInactiveAt = performance.now()
    } else {
      let delta = performance.now() - visibilityReporter.documentInactiveAt
      visibilityReporter.documentInactiveAt = 0

      Object.keys(visibilityReporter.timers).forEach((k) => {
        if (visibilityReporter.timers[k].isVisible) visibilityReporter.timers[k].startedAt = visibilityReporter.timers[k].startedAt + delta
      })
    }
  },

  reportScroll(event) {
    if (event.totalViewTime > 1000) {
      // segment IO
      Segment.getAnalytics().then((ana) => ana.track(event.id, event))
    }
  },
}

export default visibilityReporter
