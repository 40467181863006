import HeadlineTop from '../../common/HeadlineTop/HeadlineTop'
import EmptyTickIcon from '../../Icons/EmptyTick'
import Segment from 'common/SegmentIO'
import './Pricing.scss'

const Pricing = () => {
  const list = [
    {
      title: 'Starter Plan',
      price: 'Free',
      description: 'Start-up essentials to get your business growing.',
      services: [
        'From generation to execution and storage',
        'Long term management: renewals, termination, amendments',
        'Unlimited NDAs',
        '3 monthly free template contracts',
        'Pay $30 per executed contract afterwards',
      ],
      action: 'Start now',
      actionDest: 'https://app.yumidocs.com',
      style: { backgroundColor: '#bfe180', borderColor: '#bfe180' },
    },
    {
      title: 'Business Plan',
      price: 'Flat fee at $500/ month',
      description: 'More contracts managed efficiently',
      services: [
        <div>
          All features in <strong>Starter</strong>&#44; plus
        </div>,
        'Bring your own bespoke templates',
        'Unlimited bespoke templates',
        'Unlimited free-templates contracts',
        'Manage up to 12 users',
        'Approved signatories',
        'Business support',
        'Integrations with bespoke 3rd providers',
        'API access',
      ],
      action: 'Start now',
      actionDest: 'https://app.yumidocs.com',
      style: { backgroundColor: '#fdd1a5', borderColor: '#fdd1a5' },
    },
    // {
    //   title: 'Enterprise Plan',
    //   price: "Let's talk",
    //   description: 'Empower teams in large organizations',
    //   services: [
    //     <div>
    //       All features in <strong>Business</strong>&#44; plus
    //     </div>,
    //     'Bring your own contracts',
    //     'SSO integration',
    //     'End to end automation via APIs',
    //     <div>
    //       UK Gov compliant <br /> electronic deed execution
    //     </div>,
    //     'Priority support tiers',
    //   ],
    //   action: 'Contact us',
    //   actionDest: '#ContactUs',
    //   style: { backgroundColor: '#ff9595', borderColor: '#ff9595', '&:hover': { backgroundColor: '#ff9595b8', borderColor: '#ff9595b8' } },
    // },
  ]

  const allPlans = {
    title: 'All plans include',
    services: [
      'Your clients get YumiDocs for free',
      'Native Electronic signature - no integration required',
      'Offline execution with Qualified or Advanced Electronic signature',
      'DOCX and PDF export for all contracts',
      'Access to all YumiDocs curated templates',
      'Collaborative contract drafting and generation',
      'Multi-party negotiation workflow',
      'Agreement change history',
      'Maximum contractual data reusability',
      'Contextual communication with your counterparties',
      'Contract renewals, amendments, termination',
      'Expiration notifications',
      'Approval workflows',
      'Audit trail',
      'Comments',
      'Document storage and management',
      'Contract portfolio analytics',
    ],
  }

  return (
    <section className="Pricing" id="Pricing">
      <HeadlineTop>Pricing</HeadlineTop>

      <article className="Pricing__content">
        {list.map((item, key) => (
          <div key={key} className="Pricing__item">
            <header>
              <h4>{item.title}</h4>
              <div>{item.description}</div>
              <h2 className="price">
                {item.price} {item.priceUnit && <span>{item.priceUnit}</span>}
              </h2>
              <div className="actions">
                <a
                  href={item.actionDest}
                  target={item.actionDest.charAt(0) !== '#' ? '_blank' : ''}
                  className="btn small"
                  rel="noopener noreferrer"
                  style={item.style}
                  onClick={() => {
                    Segment.getAnalytics().then((ana) => ana.track(item.action, { section: 'Pricing', title: item.title }))
                  }}
                >
                  {item.action}
                </a>
              </div>
            </header>
            <ul>
              {item.services.map((text, k) => (
                <li key={k}>
                  <EmptyTickIcon /> {text}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </article>

      <article className="all-plans">
        <header>{allPlans.title}</header>
        <ul>
          {allPlans.services.map((text, k) => (
            <li key={k}>
              <EmptyTickIcon /> {text}
            </li>
          ))}
        </ul>
      </article>
    </section>
  )
}

export default Pricing
