import HeadlineTop from '../../common/HeadlineTop/HeadlineTop'
import CloudPlatform from '../../Icons/CloudPlatform'
import DataRepo from '../../Icons/DataRepo'
import Folders from '../../Icons/Folders'
import './OurProposal.scss'

const OurProposal = () => {
  const list = [
    {
      id: 'TemplateLibrary',
      icon: <Folders width="50px" />,
      title: 'Template Library',
      description: (
        <div>
          <p>One, central repository for your templates and pre-approved playbooks. </p>
          <p>
            Enable your teams to self-service their daily needs. We
            get you started with YumiDocs' templates verified by our lawyers.
          </p>
          <p>Focus on what you want to achieve. We take care of how it happens.</p>
        </div>
      ),
    },
    {
      id: 'Platform',
      icon: <CloudPlatform width="50px" color="#e88403" />,
      title: 'Platform and Tools',
      description: (
        <div>
          <p>A unified, end-to-end contract management platform with everything included.</p>
          <p>Draft and negotiate better contracts, faster. This is where so much time is lost fiddling with words instead of business terms.</p>
          <p>Store and manage your contracts for the long-term using key business terms. </p>
        </div>
      ),
    },
    {
      id: 'Data',
      icon: <DataRepo width="50px" />,
      title: 'Contractual Data',
      description: (
        <div>
          <p>Contracts turn into key business terms so you can manage concepts that make sense for your company right away. No need to open PDF files and read more text.</p>
          <p>Contractual data from your contracts can drive automation, workflows and the next contract renewal step.</p>
          <p>Risk, compliance, regulatory governance, M&A becomes easy and a non-problem.</p>
        </div>
      ),
    },
  ]

  return (
    <section className="OurProposal" id="OurProposal">
      <HeadlineTop>A complete solution for digital contracts</HeadlineTop>
      <p className="description">Enable contract agility in company-wide functions.</p>
      <article className="OurProposal__content">
        {list.map((item, key) => (
          <div key={key} className="OurProposal__item" id={item.id}>
            <div className="top">
              <div className="icon">{item.icon}</div>
              <div className="title">
                <h4>{item.title}</h4>
              </div>
            </div>
            {item.description}
          </div>
        ))}
      </article>
    </section>
  )
}

export default OurProposal
