const Icon = (props) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 294.843 294.843"
      {...props}
    >
      <g>
        <path d="M6,40.77c3.313,0,6-2.686,6-6c0-9.256,7.53-16.786,16.786-16.786h53.675l42.243,46.147
		c1.137,1.242,2.743,1.949,4.426,1.949h136.928c9.256,0,16.786,7.53,16.786,16.786c0,3.314,2.687,6,6,6s6-2.686,6-6
		c0-15.873-12.913-28.786-28.786-28.786H131.771L89.529,7.933c-1.137-1.242-2.743-1.949-4.426-1.949H28.786
		C12.913,5.984,0,18.897,0,34.77C0,38.083,2.687,40.77,6,40.77z" />
        <path d="M266.057,94.104H131.771L89.529,47.957c-1.137-1.242-2.743-1.949-4.426-1.949H28.786C12.913,46.008,0,58.921,0,74.793
		v118.628c0,3.313,2.687,6,6,6s6-2.687,6-6V74.793c0-9.256,7.53-16.786,16.786-16.786h53.675l42.243,46.147
		c1.137,1.242,2.743,1.949,4.426,1.949h136.928c9.256,0,16.786,7.53,16.786,16.786v137.184c0,9.255-7.53,16.786-16.786,16.786
		H28.786c-9.256,0-16.786-7.53-16.786-16.786c0-3.313-2.687-6-6-6s-6,2.687-6,6c0,15.873,12.913,28.786,28.786,28.786h237.271
		c15.873,0,28.786-12.913,28.786-28.786V122.89C294.843,107.017,281.93,94.104,266.057,94.104z" />
      </g>
    </svg>
  )
}

export default Icon
