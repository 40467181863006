const Icon = ({ color = '#1d1c3b' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="167" height="40" viewBox="0 0 167 40">
    <g>
      <path
        d="M5.9,13.071a5.83,5.83,0,0,1-3.105-.815A5.528,5.528,0,0,1,.733,9.974,7.606,7.606,0,0,1,0,6.548,7.677,7.677,0,0,1,.733,3.105,5.523,5.523,0,0,1,2.792.815,5.845,5.845,0,0,1,5.9,0,5.842,5.842,0,0,1,9,.815a5.515,5.515,0,0,1,2.059,2.29A7.676,7.676,0,0,1,11.8,6.548a7.6,7.6,0,0,1-.734,3.426A5.521,5.521,0,0,1,9,12.255,5.828,5.828,0,0,1,5.9,13.071ZM5.906,2.059a2.969,2.969,0,0,0-1.923.617A3.65,3.65,0,0,0,2.854,4.311a6.6,6.6,0,0,0-.366,2.228,6.577,6.577,0,0,0,.366,2.232,3.6,3.6,0,0,0,1.129,1.623A2.991,2.991,0,0,0,5.906,11a2.956,2.956,0,0,0,1.91-.609A3.607,3.607,0,0,0,8.942,8.771a6.556,6.556,0,0,0,.366-2.232,6.582,6.582,0,0,0-.366-2.228A3.663,3.663,0,0,0,7.816,2.677,2.935,2.935,0,0,0,5.906,2.059Z"
        transform="translate(129.84 18.382)"
        fill="currentColor"
      />
      <path
        d="M5.9,13.071a5.79,5.79,0,0,1-3.159-.836A5.476,5.476,0,0,1,.708,9.925,7.722,7.722,0,0,1,0,6.547,7.64,7.64,0,0,1,.726,3.142,5.561,5.561,0,0,1,2.768.831,5.7,5.7,0,0,1,5.873,0a5.972,5.972,0,0,1,2.57.53,4.675,4.675,0,0,1,1.825,1.487,4.5,4.5,0,0,1,.827,2.232H8.7a2.879,2.879,0,0,0-.9-1.532,2.685,2.685,0,0,0-1.883-.642,3.057,3.057,0,0,0-1.8.54,3.509,3.509,0,0,0-1.2,1.532A5.874,5.874,0,0,0,2.488,6.49a6.158,6.158,0,0,0,.42,2.388A3.53,3.53,0,0,0,4.1,10.435a3.04,3.04,0,0,0,1.816.551,3.037,3.037,0,0,0,1.273-.259,2.59,2.59,0,0,0,.964-.746A2.846,2.846,0,0,0,8.7,8.812h2.4A4.651,4.651,0,0,1,10.3,11a4.6,4.6,0,0,1-1.783,1.516,5.844,5.844,0,0,1-2.615.551"
        transform="translate(143.229 18.382)"
        fill="currentColor"
      />
      <path
        d="M10.04,3.253l-2.232.4a2.691,2.691,0,0,0-.441-.816A2.229,2.229,0,0,0,6.556,2.2a2.927,2.927,0,0,0-1.276-.247,3.087,3.087,0,0,0-1.746.466,1.392,1.392,0,0,0-.7,1.2A1.257,1.257,0,0,0,3.3,4.636a3.79,3.79,0,0,0,1.515.634l2.01.462a5.4,5.4,0,0,1,2.6,1.244,2.924,2.924,0,0,1,.857,2.182,3.313,3.313,0,0,1-.655,2.022,4.336,4.336,0,0,1-1.82,1.388,6.773,6.773,0,0,1-2.7.5,6.057,6.057,0,0,1-3.468-.91A3.816,3.816,0,0,1,0,9.571l2.38-.363a2.262,2.262,0,0,0,.914,1.4,3.139,3.139,0,0,0,1.8.473,3.321,3.321,0,0,0,1.936-.507A1.481,1.481,0,0,0,7.759,9.34a1.3,1.3,0,0,0-.441-1,2.953,2.953,0,0,0-1.346-.609L3.83,7.264A5.114,5.114,0,0,1,1.214,5.98,3.1,3.1,0,0,1,.37,3.747,3.19,3.19,0,0,1,1,1.787,4.123,4.123,0,0,1,2.726.473,6.361,6.361,0,0,1,5.255,0,5.265,5.265,0,0,1,8.484.884,4.163,4.163,0,0,1,10.04,3.253"
        transform="translate(155.828 18.382)"
        fill="currentColor"
      />
      <path
        d="M.078,9.909.032,9.175c-.008-.087-.01-.339-.014-.539L.01,8.006C0,7.164,0,6.3.019,5.418.048,3.654.125,1.871.2.037L.571,0a3.679,3.679,0,0,1,.441.025L1.92.106,3.71.277A22.152,22.152,0,0,1,7.368.9c.623.159,1.25.362,1.872.586.312.1.617.242.925.362.156.061.3.126.452.193l.326.149a23.192,23.192,0,0,1,2.322,1.257,28.226,28.226,0,0,1,7.419,6.781A20.92,20.92,0,0,1,24.358,17.4a19.286,19.286,0,0,1,.468,8.538,19.872,19.872,0,0,1-.5,2.132c-.2.692-.47,1.417-.7,1.97l-.159.414-.2.486c-.138.323-.284.645-.439.964A20.642,20.642,0,0,1,21.8,33.786a19.191,19.191,0,0,1-6.012,6.056c-.172-.287.287-1.19-.543-.847a5.234,5.234,0,0,1-3,.664,1.031,1.031,0,0,1-.772-.731c-.068-.153.464-.39.9-.611s.777-.41.356-.372a13.436,13.436,0,0,0,3-2.155c.686-.707.867-1.052-.1-.412a18.283,18.283,0,0,0-1.935,1.2,4.257,4.257,0,0,1-2.1.814c-.864-.138.2-1.215-.66-1.357a24.2,24.2,0,0,1-2.569,1.055c-.761-.092.318-1.176-.329-.951C6.024,36.5,3.343,37.294.465,37.832c-.071-.329-.838-.521.551-.961,4.513-.934,8.817-2.126,11.587-4.479A11.544,11.544,0,0,0,15.8,28.053c.1-.217.184-.436.272-.665l.3-.793a12.714,12.714,0,0,0,.4-1.3,11.705,11.705,0,0,0,.328-2.665,14.637,14.637,0,0,0-4.344-10.082A16.342,16.342,0,0,0,8.139,9.2l-.319-.15-.2-.086A.119.119,0,0,0,7.6,8.962l-.044-.007c-.025,0-.062-.012-.075,0l-.012.228c-.023,1.695-.022,3.4-.015,5.121.014,3.435.054,6.909.007,10.411-.161,3.015-.827.745-1.353-1.487.174.745-.328.749-.155,1.5-.5,0-1.346-1.486-1.562-4.447L4.418,22.5c-.158.745-.5.006-.838.01-.008-.742-.021-1.482-.188-1.479l-.671.01L2.757,24c0-.738-.016-1.478-.183-1.476l-.671.009c-.185-1.473.11-3.7-.084-5.178l-.5.008c-.143,1.487.066,3.7.083,5.175-.177-.735-.345-.733-.68-.728-.2-2.207.258-4.437-.278-6.662l.035,2.23c-.864-1.467-.254-5.208-.4-7.471"
        transform="translate(102.197)"
        fill="currentColor"
      />
      <path
        d="M4.444,23.9a7.62,7.62,0,0,1-3.3-3.364A11.921,11.921,0,0,1,0,15.055V.726A.641.641,0,0,1,.726,0H4.716a.641.641,0,0,1,.726.726v14.04a9.645,9.645,0,0,0,.381,2.956A3.245,3.245,0,0,0,7.129,19.49,4.543,4.543,0,0,0,9.65,20.1a4.667,4.667,0,0,0,2.567-.608,3.273,3.273,0,0,0,1.324-1.769,9.435,9.435,0,0,0,.39-2.956V7.582a9.965,9.965,0,0,1,.689-3.864,6.174,6.174,0,0,1,1.923-2.594A5.616,5.616,0,0,1,19.373,0V14.766q0,5.078-2.53,7.672T9.65,25.032A11.063,11.063,0,0,1,4.444,23.9"
        transform="translate(29.384 6.424)"
        fill={color}
      />
      <path
        d="M31.309,23.871a.861.861,0,0,1,.018.2.574.574,0,0,1-.172.436.7.7,0,0,1-.5.163H26.52a.718.718,0,0,1-.8-.653L22.638,9.2l-2.721,8.725a21.581,21.581,0,0,1-1.651,4.091,7.5,7.5,0,0,1-1.832,2.276A6.289,6.289,0,0,1,14.149,25.4L9.033,9.178,7.292,17.522A11.7,11.7,0,0,1,4.571,23.4,6.136,6.136,0,0,1,0,25.4L5.913.616A.751.751,0,0,1,6.712,0h3.737a.81.81,0,0,1,.516.163.8.8,0,0,1,.282.453l4.553,14.8L20.425.616a.8.8,0,0,1,.281-.453A.81.81,0,0,1,21.223,0H24.96a.752.752,0,0,1,.8.616Z"
        transform="translate(50.824 6.424)"
        fill={color}
      />
      <path
        d="M8.961,4.934v14.8h2.793a.642.642,0,0,1,.726.726v3.482a.642.642,0,0,1-.726.726H.726A.641.641,0,0,1,0,23.944V20.461a.642.642,0,0,1,.726-.726H3.519V12.153A9.216,9.216,0,0,1,4.589,7.51a5.642,5.642,0,0,1,2.9-2.576H.726A.641.641,0,0,1,0,4.208V.726A.641.641,0,0,1,.726,0H11.754a.641.641,0,0,1,.726.726V4.208a.641.641,0,0,1-.726.726Z"
        transform="translate(85 6.423)"
        fill={color}
      />
      <path
        d="M2.738,8.942,4.891,5.475A11.115,11.115,0,0,1,9.17,1.405,12.205,12.205,0,0,1,15,0L5.475,13.687V24.819a.645.645,0,0,1-.73.73H.73a.646.646,0,0,1-.73-.73V13.687"
        transform="translate(11.618 6)"
        fill={color}
      />
      <path
        d="M11.69,10.281a9.382,9.382,0,0,1-4.638-.535A8.6,8.6,0,0,1,3.716,6.7L.141.847A.537.537,0,0,1,.294,0H4.945c.492-.048.841.517,1.073.849l5.7,9.41"
        transform="translate(0 6.221)"
        fill={color}
      />
    </g>
  </svg>
)

export default Icon
