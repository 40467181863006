const Icon = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 411.107 411.107"
    {...props}
  >
    <g>
      <path d="M406.286,205.55L352.587,96.118h43.295c4.142,0,7.5-3.358,7.5-7.5c0-4.142-3.358-7.5-7.5-7.5h-171.58V59.603
			c8.463-5.929,14.012-15.747,14.012-26.842C238.314,14.697,223.618,0,205.553,0s-32.761,14.697-32.761,32.761
			c0,11.094,5.548,20.911,14.01,26.84v21.517H15.222c-4.142,0-7.5,3.358-7.5,7.5c0,4.142,3.358,7.5,7.5,7.5H58.52L4.82,205.55
			c-0.505,1.028-0.767,2.158-0.767,3.304c0,36.668,29.832,66.5,66.5,66.5c36.668,0,66.5-29.832,66.5-66.5
			c0-1.146-0.262-2.276-0.767-3.304L82.587,96.118h104.215v226.491c0,25.573-20.806,46.378-46.379,46.378
			c-4.142,0-7.5,3.358-7.5,7.5v27.12c0,4.142,3.358,7.5,7.5,7.5h130.258c1.989,0,3.897-0.79,5.303-2.197
			c1.407-1.407,2.197-3.314,2.197-5.303v-27.12c0-4.142-3.358-7.5-7.5-7.5c-25.573,0-46.378-20.805-46.378-46.378V96.118H328.52
			l-53.7,109.432c-0.505,1.028-0.767,2.158-0.767,3.304c0,36.668,29.832,66.5,66.5,66.5c36.668,0,66.5-29.832,66.5-66.5
			C407.053,207.708,406.791,206.578,406.286,205.55z M70.553,260.354c-25.85,0-47.3-19.146-50.943-44h101.885
			C117.853,241.208,96.403,260.354,70.553,260.354z M117.518,201.354h-93.93l46.965-95.709L117.518,201.354z M205.553,15
			c9.793,0,17.761,7.968,17.761,17.761c0,9.793-7.967,17.761-17.761,17.761s-17.761-7.967-17.761-17.761S195.76,15,205.553,15z
			 M263.181,383.986v12.12H147.924v-12.12H263.181z M230.526,368.987h-49.948c12.991-11.263,21.224-27.876,21.224-46.378V65.301
			c1.232,0.141,2.482,0.221,3.751,0.221c1.268,0,2.517-0.08,3.749-0.221v257.307C209.302,341.111,217.536,357.724,230.526,368.987z
			 M340.553,105.645l46.965,95.709h-93.93L340.553,105.645z M340.553,260.354c-25.85,0-47.3-19.146-50.943-44h101.885
			C387.853,241.208,366.403,260.354,340.553,260.354z" />
    </g>
  </svg>
)

export default Icon
