
import './Security.scss'
import ShieldIcon from '../../Icons/Shield'

const Security = () => {
  return (
<section className="SecurityPolicy">
    <div className="SecurityPolicy__content">
        <h1>YumiDocs Security</h1>
        <p>Last updated on May 17<sup>th</sup>, 2021.</p>
        <div class="highlights">
            <ul >
                <li>
                    <div>
                        <div class="himg" ><ShieldIcon /></div>
                        <div class="highlightTitle">Secure infrastructure</div>
                    </div>
                    <p>YumiDocs runs in Google Cloud Platform (GCP) and it's using best cloud tools and practices to ensure customers' data is secure and reliably stored.</p>
                    
                </li>
                <li>
                    <div>
                        <div class="himg" ><ShieldIcon /></div>
                        <div class="highlightTitle">Data is encrypted in-transit and at-rest</div>
                    </div>
                    <p>Our customers' data is encrypted when in transit and at rest, using the highest industry standard procedures and protocols, including TLS 1.3, FIPS 140-2 validated HSMs (GCP KMS), and AES-256 symmetric encryption algorithms where appropriate.</p>
                </li>
                <li>
                    <div>
                        <div class="himg" ><ShieldIcon /></div>
                        <div class="highlightTitle">Data access is controlled</div>
                    </div>
                    <p>Access to customer data is strictly limited to a small set of engineers and it is protected by auditing & read-only logging systems.</p>
                </li>
                <li>
                    <div>
                        <div class="himg" ><ShieldIcon /></div>
                        <div class="highlightTitle">Best security practices in place</div>
                    </div>
                    <p>YumiDocs adheres to industry's best practices in writing secure code, application testing and managing infrastructure.</p>
                </li>
                <li>
                    <div>
                        <div class="himg" ><ShieldIcon /></div>
                        <div class="highlightTitle">GDPR and DPA compliance</div>
                    </div>
                    <p>YumiDocs is in full compliance with European General Data Protection Regulation (GDPR) and Data Protection Act (DPA) provisions.</p>
                </li>
            </ul>
        </div>
        <p>More details on our security practices are available upon request at <a href="mailto:securit@yumidocs.com">security@yumidocs.com</a></p>
        <div>
            <h2>Cloud Infrastructure first</h2>
            <p>YumiDocs is hosted on Google Cloud Platform (GCP). The data centre YumiDocs uses within GCP is located in
                the UK and it is entirely managed and owned by Google. For additional information on the security of
                GCP, visit <a href="https://cloud.google.com/security/compliance" target="_blank" rel='noopener noreferrer'>Google Compliance &
                    Regulation Portal</a>.</p>
        </div>
        <div>
            <h2>Encryption</h2>
            <div>
                <h3>Key management</h3>
                <p>YumiDocs utilises the GCP’s Key Management Service (KMS) for creating, maintaining, and rotating all
                    symmetric encryption keys. YumiDocs does not store or maintain cleartext private key material on
                    disk or in-memory. For document signing we For more information about GCP KMS and its security
                    architecture, please consult <a href="https://cloud.google.com/security-key-management"
                        target="_blank" rel='noopener noreferrer'>GCP whitepapers on KMS</a>. </p>
            </div>
            <div>
                <h3>Document singing certificates</h3>
                <p>The document signing certificates used by YumiDocs is issued by a provider part of the Adobe Approved
                    Trust List as well as European Union Trust List. The certificate cryptographic key materials are
                    entirely stored in a physical Hardware Secure Module (HSM) in GCP. This ensures the key is unique
                    and cannot be exported. The HSM solution provided by GCP is <a
                        href="https://csrc.nist.gov/publications/detail/fips/140/2/final" target="_blank" rel='noopener noreferrer'>FIPS 140-2
                        Level 3</a> certified and more information can be found at <a
                        href="https://cloud.google.com/kms/docs/hsm" target="_blank" rel='noopener noreferrer'>Google Cloud HSM</a>. </p>
            </div>
            <div>
                <h3>In-flight</h3>
                <p>YumiDocs utilises Transport Layer Security (TLS 1.3) and using X25519 and AES_256_GCM algorithms to
                    protect user data and information in transit. HTTPS traffic is terminated at our load balancers in
                    GCP and the encryption keys material are managed by our infrastructure and stored in the Cloud KMS
                    in GCP. The communication between load balancers and the internal application services is also
                    further encrypted with IPSEC. </p>
            </div>
            <div>
                <h3>At-rest</h3>
                <p>End user data is stored at rest only in encrypted form. All key materials used in data encryption are
                    stored in the Cloud KMS. YumiDocs infrastructure is configured to store data in encrypted form using
                    the industry standard 256-bit Advanced Encryption Standard (AES-256) keys in Galois Counter Mode
                    (GCM), padded with Cloud KMS-internal metadata for the database, backups, snapshots, and logs.
                    Content stored in Google Cloud Storage is also similarly encrypted at rest via with key materials
                    only stored in GCP KMS.</p>
            </div>
        </div>
        <div>
            <h2>Network Security</h2>
            <p>YumiDocs infrastructure provisioned within Google Cloud Platform is configured within a Virtual Private
                Network (VPN). This VPN uses declaratively-managed firewall rules and network ACLs that restrict access
                in and out of the VPN and its subnets to those protocols and ports necessary for the functioning of the
                application.</p>
        </div>
        <div>
            <h2>Organizational Security Practices</h2>
            <p>YumiDocs employees are using corporate-managed accounts. Corporate accounts utilise a single-sign-on
                system using multi-factor authentication along with strong passwords.</p>
        </div>
        <div>
            <h2>User Data Privacy</h2>
            <p>The privacy of user data is important and access to it by YumiDocs employees is subject to published
                policies and procedures. All access to our internal administration tools is logged and periodically
                reviewed. Access to the admin interfaces are divided by information type, and access to data is granted
                as is deemed required for job function.</p>
        </div>
        <div>
            <h2>Authentication</h2>
            <div>
                <h3>Email & Password</h3>
                <p>Passwords are secured using an industry standard salt, and a secure hash mechanism with
                    multiple rounds of computation. Two-factor authentication is available and advised for all accounts
                    using email & password as their authentication mechanism.</p>
            </div>
            <div>
                <h3>Google Integration</h3>
                <p>Authentication of users within YumiDocs is delegated to Google via the industry-standard <a
                        href="https://oauth.net/2/" target="_blank" rel='noopener noreferrer'>OAuth2</a> protocol; YumiDocs has no knowledge about
                    a user's password. During login, Google authenticates the users with a password and
                    multi-factor authentication which is enforced for all users of the organisation. GSuite
                    administrators can configure any password-complexity and multi-factor enforcement policies within
                    the admin console. After authentication, YumiDocs requests access to a number of OAuth "scopes" in
                    order to provide access to limited account data, thereby enabling the YumiDocs integration with
                    Google. This account access can be revoked by admin users at any time via the Google Accounts
                    console at <a href="https://myaccount.google.com/permissions" target="_blank" rel='noopener noreferrer'>https://myaccount.google.com/permissions</a>, or via a GSuite admin using the
                    GSuite admin console.</p>
            </div>
            <div>
                <h3>SSO via SAML 2.0</h3>
                <p>The enterprise users may integrate YumiDocs with their existing IdP (Identity Provider) service to
                    enable seamless single-sign on (SSO) for their users.</p>
            </div>
        </div>
        <div>
            <h2>GDPR / DPA</h2>
            <p>YumiDocs is in full compliance with European General Data Protection Regulation (GDPR) and DPA provisions. More detail on YumiDocs’s privacy policy <a
                    href="https://yumidocs.com/privacy-policy" target="_blank" rel='noopener noreferrer'>https://yumidocs.com/privacy-policy</a>.
            </p>
        </div>
    </div>
</section>
  )
}

export default Security
