const Icon = (props) => (
  <svg width="114" height="114" viewBox="0 0 114 114" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_946_396)">
      <path
        d="M14.7859 90.5573C19.7174 83.8679 26.1709 78.8783 32.9723 74.4477C38.4928 70.854 44.7822 71.6209 50.6397 73.9657C54.2274 75.3996 57.7761 75.5736 61.2248 74.5267C65.8254 73.1378 70.45 72.9878 75.1446 72.9738C77.1564 72.9658 78.8282 73.6708 80.0261 75.2486C80.6951 76.1315 75.2146 79.8032 70.513 79.8032C71.3859 81.5451 86.5816 77.8564 88.6074 78.0064L100.103 76.5355C104.189 76.2035 104.183 74.6597 108.202 76.9315C108.339 77.0095 108.539 77.0095 108.703 76.9995C110.938 76.8725 112.2 77.5814 113.444 79.0623C114.162 79.9152 114.103 80.9651 113.721 81.96C113.317 83.004 112.291 82.733 111.45 83.0649C106.309 85.1008 104.651 86.0967 97.6796 87.8185C92.722 89.0434 87.8895 90.5383 82.9379 91.6572C80.8921 92.1232 78.8092 92.4691 76.9634 93.506C73.0418 95.7158 68.8721 96.3838 64.3915 96.1818C59.307 95.9498 54.2074 96.1548 49.1099 96.1238C40.1646 96.0828 33.3722 99.6645 28.7736 107.542L-0.000838346 107.48C6.27861 100.135 9.04737 98.3556 14.7859 90.5573Z"
      />
      <path
        d="M67.6667 46.3396C67.6667 55.6738 72.3028 59 76.3286 59H86.7083C94.5896 59 101 52.4231 101 44.3419C101 36.2607 94.5896 29.6838 86.7083 29.6838H84.1271C80.7313 23.0812 74.1792 19 66.9167 19C56.4167 19 47.8271 27.5577 47.4292 38.2308H47.125C41.5417 38.2308 37 42.8889 37 48.6154C37 54.3419 41.5438 59 47.125 59H63.6608V57.4594H47.1292C42.3688 57.4594 38.5 53.4914 38.5 48.6132C38.5 43.735 42.3667 39.7671 47.125 39.7671H48.1667C48.5813 39.7671 48.9167 39.4231 48.9167 38.9979C48.9167 28.8184 56.9917 20.5363 66.9167 20.5363C73.7604 20.5363 79.9208 24.4679 82.9979 30.7949C83.125 31.0577 83.3833 31.2222 83.6688 31.2222H86.7083C93.7625 31.2222 99.5 37.1068 99.5 44.3419C99.5 51.5769 93.7625 57.4615 86.7083 57.4615L76.3286 57.4594C69.5 57.4594 68.9946 50.34 68.9946 46.3396C68.9946 46.3396 68.3279 46.3396 67.6667 46.3396Z"
        strokeWidth="2"
        stroke={props.fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_946_396">
        <rect width="114" height="114" fill="white" transform="matrix(-1 0 0 1 114 0)" />
      </clipPath>
    </defs>
  </svg>
)

export default Icon
