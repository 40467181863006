import './TemplateLibrary.scss'

import HeadlineTop from '../../common/HeadlineTop/HeadlineTop'
import TickIcon from '../../Icons/Tick'
import ExternalUrl from '../../Icons/ExternalUrl'

const TemplateLibrary = () => {
  const list = [
    {
      title: <strong>Standardized Templates</strong>,
      text: [
        {
          text: 'Balanced for all parties',
        },
        { text: 'Verified by lawyers' },
        {
          text: 'Easy to read and understand',
        },
        {
          text: 'Parameterized clauses',
        },
        {
          text: 'DOCX and PDF export available',
        },
        {
          text: 'Comparison with counterparty position',
        },
        {
          text: 'Automatic blacklining',
        },
        {
          text: 'History of changes',
        },
        {
          text: 'Fast fill in, with no mistakes',
        },
        {
          text: 'Reusable term profiles',
        },
      ],
    },
    {
      title: <strong>Domains</strong>,
      text: [
        {
          text: 'IP Assignment Rights',
        },
        {
          text: 'Standardized NDA',
        },
        {
          text: 'NDA - Bonterms',
          source: 'https://bonterms.com',
        },
        {
          text: 'Standardized SaaS Agreement - Cloud Order Form and Terms',
          source: 'https://bonterms.com',
        },
        {
          text: 'Purchase Order Forms',
        },
        {
          text: 'Service Agreement',
        },
        { text: 'Full-time Employment Agreement' },
        {
          text: 'Contractor Agreement (US)',
          source: 'https://squareoneforms.com',
        },
        {
          text: 'Standardized Employment Agreement',
          source: 'https://squareoneforms.com',
        },
        {
          text: 'Statement of Work',
          source: 'https://squareoneforms.com',
        },
        {
          text: <strong>Bring your own templates</strong>,
        },
      ],
    },
  ]

  return (
    <section className="TemplateLibrary" id="TemplateLibrary">
      <HeadlineTop>Template Library</HeadlineTop>
      <article className="TemplateLibrary__content">
        {list.map((item, key) => (
          <div key={key} className="TemplateLibrary__item">
            <h4>{item.title}</h4>
            <ul>
              {item.text.map((el, key) => {
                return (
                  <li key={key}>
                    <TickIcon /> {el.text}{' '}
                    {el.source && (
                      <a href={el.source} target="_blank" rel="noreferrer">
                        <ExternalUrl />
                      </a>
                    )}
                  </li>
                )
              })}
            </ul>
          </div>
        ))}
      </article>
    </section>
  )
}

export default TemplateLibrary
