import './PrivacyPolicy.scss'

const PrivacyPolicy = () => {
  return (
    <section className="PrivacyPolicy">
      <div className="PrivacyPolicy__content">
        <h1 id="privacy-policy">Privacy Policy</h1>
        <p>
          Last updated on May 16<sup>th</sup>, 2022.
        </p>
        <p>
          This Privacy Policy describes the information that we gather on or through the Service, how we use and disclose such information,
          and the steps we take to protect such information. Please read the following carefully to understand our views and practices
          regarding your personal data and how we will treat it. By visiting the Site, or by using our Service, you accept the privacy
          practices described in this Policy.
        </p>
        <p>
          If you do not agree with parts of this Privacy Policy, please don’t use our service and get in contact with our team to best see
          how we can protect your data at{' '}
          <a href="mailto:privacy@yumidocs.com" class="email">
            privacy@yumidocs.com
          </a>.
        </p>
        
        <h2 id="definitions">1. Definitions</h2>
        <p>
          Yumi Ltd, of 20-22 WENLOCK RD, London, England, N1 7GU, (the “<strong>Company</strong>” “<strong>We</strong>”, “<strong>YumiDocs</strong>”,“<strong>Us</strong>”
          and “<strong>Our</strong>”) are committed to protecting and respecting your privacy and following the principles behind GDPR, DPA
          and CCPA.
        </p>
        <p>
          <strong>“Client”</strong> means a customer of the company.
        </p>
        <p>
          <strong>“Client Data”</strong> means personal data, reports, addresses, and other files, folders or documents in electronic form
          that a User of the Service or the Website stores within the Service.
        </p>
        <p>
          “<strong>Act</strong>” or “<strong>DPA</strong>” means “Data Protection Act 1998” with its updates and later modifications.
        </p>
        <p>
          “<strong>GDPR</strong>” means the{' '}
          <a href="http://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv:OJ.L_.2016.119.01.0001.01.ENG&amp;toc=OJ:L:2016:119:TOC">
            EU General Data Protection Regulation 2018 (GDPR)
          </a>.
        </p>
        <p>
          <strong>“Personal Data”</strong> means any information relating to an identified or identifiable natural person.
        </p>
        <p>
          “<strong>Policy</strong>” and “<strong>Privacy Policy</strong>” means this Privacy Policy document with all the documents and
          hyperlinks attached, displayed and referred to.
        </p>
        <p>
          <strong>“Public Area”</strong> means the area of the Site that can be accessed both by Users and Visitors, without needing to log
          in.
        </p>
        <p>
          <strong>“Restricted Area”</strong> means the area of the Site that can be accessed only by Users, and where access requires
          logging in.
        </p>
        <p>
          “<strong>Our Site</strong>”, the “<strong>Site</strong>”, the “<strong>Website</strong>”, “<strong>our Service</strong>” or the “<strong>Service</strong>”
          means the services provided by us through www.yumidocs.com with all related web site applications and other services provided by
          Us and on which a link to this Privacy Policy is displayed, and all other communications with individuals and companies though
          from written, digital or oral means.
        </p>
        <p>
          <strong>“User”</strong> means an employee, agent, or representative of a Client, who primarily uses the restricted areas of the
          Site for the purpose of accessing the Service in such capacity.
        </p>
        <p>
          <strong>“Visitor”</strong> means an individual other than a User, who uses the public area, but has no access to the restricted
          areas of the Site or Service.
        </p>
        
        <h2 id="dpa-and-gdpr-role">2. DPA and GDPR role</h2>
        <p>
          For the purpose of the Data Protection Act 1998 and GDPR, when companies use our Service directly representing themselves, we act
          as Data Controller.
        </p>
        <p>
          When companies use our Service as part of their own service, with their own branding, or pricing or their own terms and conditions
          and make it available to their own clients directly we act as Data Processor and the company enters with these clients into a
          distinct data processing agreement (DPA). We always contractually commit ourselves to only process personal data on behalf and
          under the instruction of the respective customer, who is the Data Controller. Please note that this this Policy does not apply to
          such processing and we recommend you contact our client which makes the service available to you directly. If their processing
          concerns your personal information you can contact our data protection team at{' '}
          <a href="mailto:privacy@yumidocs.com" class="email">
            privacy@yumidocs.com
          </a>{' '}
          for more information.
        </p>

        <h2 id="information-we-may-collect-from-you">3. Information we may collect from you</h2>
        <p>We may collect and process the following data about you:</p>
        <h3 id="information-you-give-us.">Information you give us.</h3>
        <p>
          When you use the Service, as a User or as a Visitor or when you interact with our team, you may provide us, and we may collect
          Personal Data. Examples of Personal Data include name, email, email address, mailing address, mobile phone number, gender,
          financial and credit card information or other billing information. Personal Data also includes other information, such as
          geographic area, IP addresses or preferences, when any such information is linked to information that identifies a specific
          individual. You may provide us with Personal Data in various ways on the Service. For example, when you register for an Account,
          use the Service, post Client Data, send us questions, queries or fill in questionnaires, interact with other users of the Service
          through communication or messaging capabilities, report a problem with our Site, send us customer service-related requests or any
          other activities commonly carried out on our Site.
        </p>
        <h3 id="information-we-collect-about-you.">Information we collect about you.</h3>
        <p>With regard to each of your visits to our Site we may automatically collect the following information:</p>
        <ol type="a">
          <li>
            <p>
              technical information, including the Internet protocol (IP) address used to connect your computer to the Internet, your login
              information, browser type and version, time zone setting, browser plug-in types and versions, operating system and browser
              platform, anonymised error traces and error dumps;
            </p>
          </li>
          <li>
            <p>
              information about your visit, including the full Uniform Resource Locators (URL) clickstream to, through and from our Site
              (including date and time); articles, services or products you viewed, searched for and used; page response times, download
              errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and
              methods used to browse away from the page and any phone number used to call our customer service number; and
            </p>
          </li>
          <li>
            <p>
              service data produced by you and your account throughout the use of our service including documents you upload, generate,
              negotiate and obtain from other users, audit logs, login activity, content you type into the service, other user contact
              information revealed to you throughout the usage of the service.
            </p>
          </li>
        </ol>
        <h3 id="information-we-receive-from-our-clients-and-other-sources.">Information we receive from our Clients and other sources.</h3>
        <p>
          We may receive information about you if you use any other website or service that integrates with our Service. We are also working
          closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery
          services, analytics providers) and may receive information about you from them.
        </p>
        <h2 id="cookie-policy">4. Cookie Policy</h2>
        <p>
          Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience
          when you browse our website and also allows us to improve our Site. By continuing to browse the Site, you are agreeing to our use
          of cookies, explained as follows.
        </p>
        <p>
          We use automatically collected information and other information collected on the Service through cookies and similar technologies
          to:
        </p>
        <ol type="a">
          <li>
            <p>
              personalize our Service, such as remembering a User’s or Visitor’s information so that the User or Visitor will not have to
              re-enter it during a visit or on subsequent visits; and
            </p>
          </li>
          <li>
            <p>monitor aggregate site usage metrics such as total number of visitors and pages viewed.</p>
          </li>
        </ol>
        <p>
          You can obtain more information about cookies by visiting <a href="http://www.allaboutcookies.org/">
            http://www.allaboutcookies.org
          </a>.
        </p>
        <p>Accordingly, our Privacy Policy will apply to our treatment of the information we obtain via our cookies.</p>
        <p>
          We may also allow third party services to place cookies on your device. For example, we use Google Analytics for web analytics,
          and so Google may also set cookies on your device. As further explained below, third parties may also place cookies on your device
          for advertising purposes.
        </p>
        <p>
          Our Site may also use other technologies with similar functionality to cookies, such as web beacons and tracking URLs to obtain
          Log Data about users. We may also use web beacons and tracking URLs in our messages to you to determine whether you have opened a
          certain message or accessed a certain link.
        </p>
        <p>
          You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies.
          However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or
          parts of our Site.
        </p>
        <p>
          We can change this Cookie Policy at any time. If we make material changes to the Cookie Policy, we will let you know either by
          posting the changed Cookie Policy on our Site or by sending you an email.
        </p>
        
        <h2 id="uses-of-the-information">5. Uses of the information</h2>
        <p>We use information held about you in the following ways:</p>
        <ol type="a">
          <li>
            <p>to facilitate the creation of and securing of your account on our network;</p>
          </li>
          <li>
            <p>to identify you as a user in our Application Services;</p>
          </li>
          <li>
            <p>to comply and carry out our contractual, legal obligations and requirements arising from any contracts entered into;</p>
          </li>
          <li>
            <p>to notify you about changes to our service and significant changes to our terms of use and service;</p>
          </li>
          <li>
            <p>to process payments, subscription and service membership;</p>
          </li>
          <li>
            <p>
              to enforce this Policy, to resolve disputes, to carry out our obligations and enforce our rights, and to protect our business
              interests and the interests and rights of third parties;;
            </p>
          </li>
          <li>
            <p>to ensure that content from our Site is presented in the most effective manner to you;</p>
          </li>
          <li>
            <p>
              to administer our Site and for internal operations, including troubleshooting, data analysis, testing, research, statistical
              and survey purposes;
            </p>
          </li>
          <li>
            <p>to improve our Site to ensure that content is presented in the most effective manner for you and for your computer;</p>
          </li>
          <li>
            <p>to allow you to participate in interactive features of our service, when you choose to do so;</p>
          </li>
          <li>
            <p>as part of our efforts to keep our Site safe and secure;</p>
          </li>
          <li>
            <p>for analytics and research purposes;;</p>
          </li>
          <li>
            <p>to measure or understand the effectiveness of our service;</p>
          </li>
          <li>
            <p>
              we may combine this information with information you give to us and information we collect about you. We may use this
              information and the combined information for the purposes set out above.
            </p>
          </li>
          <li>
            <p>understand how visitors engage with our site.</p>
          </li>
          <li>
            <p>to protect, investigate, and deter against fraudulent, unauthorized, or illegal activity.</p>
          </li>
        </ol>

        <h2 id="disclosure-of-your-information">6. Disclosure of your information</h2>
        <p>
          We may disclose your personal information to third parties that help us operate the YumiDocs platform, as detailed in{' '}
          <strong>Where we store your data</strong>.
        </p>
        <p>
          We may disclose your personal information to third parties and we will always let you know in advance so that you can exercise
          your rights:
          <ol>
            <li>
              <p>
                In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective
                seller or buyer of such business or assets.
              </p>
            </li>
            <li>
              <p>
                If we or substantially all of our assets are acquired by a third party, personal data held by us about our customers may be
                one of the transferred assets.
              </p>
            </li>
            <li>
              <p>
                If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to
                enforce or apply our Terms and Conditions and other agreements; or to protect the rights, property, or safety of the
                Company, our customers, or others. This includes exchanging information with other companies and organisations for the
                purposes of fraud protection.
              </p>
            </li>
          </ol>
        </p>
        <h2 id="where-we-store-your-data">7. Where we store your data</h2>
        <p>
          The data that we collect from you is securely stored in our Google Cloud Platform servers in the United Kingdom and the European
          Eunion.
        </p>
        <ol type="a">
          <li>
            <p>
              We use Segment.IO for web and audience analytics which is set up to process and stor anonymised data in the EU. Segment.IO
              cookie and privacy policy is GDPR and DPA compliant. You can review their policy here:{' '}
              <a href="https://segment.com/legal/privacy/" class="uri">
                https://segment.com/legal/privacy/
              </a>{' '}
              .
            </p>
          </li>
          <li>
            <p>
              We use Google Analytics for web and audience analytics. The data collected by Google Analytics is stored in the UK and some
              pieces might be stored in the EU, or the USA. Where data is stored in the USA this is covered by the EU-U.S. Privacy Shield
              Framework as described here:{' '}
              <a href="https://support.google.com/analytics/answer/7105316?hl=en">support.google.com/analytics/answer/7105316?hl=en</a> .
            </p>
          </li>
          <li>
            <p>
              We use MouseFlow for web and content analytics and qualitative analysis. Mouseflow Privacy Policy can be reviewed here:{' '}
              <a href="https://mouseflow.com/privacy-shield" class="uri">
                https://mouseflow.com/privacy-shield/
              </a>{' '}
              .
            </p>
          </li>
          <li>
            <p>
              We use Sentry.io to capture anonymised error logs from our applications and ensure the quality of service. Sentry privacy
              policy is compliant with the EU-U.S. Privacy Shield Framework and can be reviewed here:{' '}
              <a href="https://sentry.io/privacy" class="uri">
                https://sentry.io/privacy
              </a>{' '}
              .
            </p>
          </li>
          <li>
            <p>
              We use Stripe to process online payments. Stripe privacy policy is compliant with the EU-U.S. and Swiss-U.S. Privacy Shield
              Framework and can be reviewed here:{' '}
              <a href="https://stripe.com/privacy-shield-policy" class="uri">
                https://stripe.com/privacy-shield-policy
              </a>{' '}
              .
            </p>
          </li>
          <li>
            <p>
              We use Mixpanel to analyse our platform and research ways of improving the customer experience. Mixpanel privacy policy is
              compliant with GDPR and CCPA. It can be reviewed here:
            </p>
            <a href="https://mixpanel.com/legal/privacy-policy/">https://mixpanel.com/legal/privacy-policy/</a>
          </li>

          <li>
            <p>
              We use Close.io to keep track of all our clients. Close.io privacy policy is compliant with GDPR and CCPA. It can be reviewed
              here:
            </p>
            <a href="https://www.close.com/privacy">https://www.close.com/privacy</a>
          </li>
          <li>
            <p>
              We use Chargebee to manage payments and subscriptions for our service. Chargebee privacy policy is compliant with GDPR and
              CCPA. It can be reviewed here:
            </p>
            <a href="https://www.chargebee.com/privacy/">https://www.chargebee.com/privacy/</a>
          </li>

          <li>
            <p>
              We use HubSpot to store our sales information. Hubspot privacy policy is compliant with the EU-U.S. and Swiss-U.S. Privacy
              Shield Framework and can be reviewed here:{' '}
              <a href="https://legal.hubspot.com/privacy-policy" class="uri">
                https://legal.hubspot.com/privacy-policy
              </a>
            </p>
          </li>
          <li>
            <p>
              We use Twillio for sending transactional email. Twillio privacy is compliant with U.S./EU Privacy Shield and can be reviwed
              here:{' '}
              <a href="https://www.twilio.com/public-policy" class="uri">
                https://www.twilio.com/public-policy
              </a>{' '}
              .
            </p>
          </li>
        </ol>
        <p>
          All information you provide to us is stored on our secure servers encrypted at rest. Any communication with all our servers will
          be encrypted using SSL technology. Where we have given you (or where you have chosen) a password which enables you to access
          certain parts of our Site, you are responsible for keeping that password confidential.
        </p>
        <p>
          Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect
          your personal data, we cannot guarantee the security of your data transmitted to our Site; any transmission is at your own risk.
          Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.
        </p>
        <h2 id="data-retention">8. Data Retention</h2>
        <p>
          We only retain the data collected from our Users and Clients for as long as the User’s account is active or otherwise for a
          limited period of time as long as we need it to fulfil the purposes for which we have initially collected it, unless otherwise
          required by law. We will retain and use information as necessary to comply with our legal obligations, resolve disputes, and
          enforce our agreements as follows:
        </p>
        <ol type="a">
          <li>
            <p>the contents of closed accounts are deleted within 6 months of the date of closure;</p>
          </li>
          <li>
            <p>backups are kept for at most 6 months;</p>
          </li>
          <li>
            <p>
              billing information is retained for a period of at most 10 years as of their provision to the accounting and taxation laws;
              and
            </p>
          </li>
          <li>
            <p>information on legal transactions between Client and us is retained for a period of at most 10 years.</p>
          </li>
        </ol>
        <h2 id="your-rights">9. Your rights</h2>
        <p>
          If you are and EU national then the GDPR gives you certain rights in relation to the data we hold about you. Full details of your
          rights are available in the text of the GDPR (see <a href="https://europa.eu/!du98Pj">europa.eu/!du98Pj</a>)
        </p>
        <h3 id="object-to-processing-and-opt-out.">Object to processing and opt-out. </h3>
        <p>
          In connection with promotions or other projects, we may ask you specifically whether you have objections against a certain kind of
          data use or sharing. If you opt-out under such circumstances, we will respect your decision. To opt out of receiving commercial
          communications from YumiDocs please contact us at{' '}
          <a href="mailto:privacy@yumidocs.com" class="email">
            privacy@yumidocs.com
          </a>. Please note that our affiliates and other data recipients have their own data privacy policies, which may differ from ours
          and you would have to contact them separately with respect to opt-out requests.
        </p>
        <h3 id="correct-or-update.">Correct or Update.</h3>
        <p>
          You may send requests about personal information to us at{' '}
          <a href="mailto:privacy@yumidocs.com" class="email">
            privacy@yumidocs.com
          </a>. You can request to change contact choices, opt-out of our sharing with others, and update your personal information.
        </p>
        <p>
          If personal information you have submitted through the site is no longer accurate, current, or complete, and you wish to update
          it, please send an e-mail to{' '}
          <a href="mailto:privacy@yumidocs.com" class="email">
            privacy@yumidocs.com
          </a>. Upon appropriate request we will usually update or amend your information, but we reserve the right to use information
          obtained previously to verify your identity or take other actions that we believe are appropriate.
        </p>
        <p>
          You may request that we delete your personal information as well as request that we make your personal information available for
          porting.
        </p>
        <h3 id="withdraw-consent.">Withdraw consent.</h3>
        <p>
          You may withdraw your consent at any time, but such withdrawal shall only apply with respect to any processing after such
          withdrawal and you may request that YumiDocs make your Personal Data portable and make such Personal Data available to you.
        </p>
        <h3 id="right-to-complain.">Right to complain.</h3>
        <p>
          You may complain to a data protection authority regarding the use or collection of your personal information. Please contact your
          local data protection authority for more information regarding how to file such a complaint.
        </p>
        <h3 id="erasure.">Erasure.</h3>
        <p>
          You can request that we erase all the data we hold about you and your account at any time. You can exercise this right by
          selecting the delete account button in your private account details or by contacting us at{' '}
          <a href="mailto:privacy@yumidocs.com" class="email">
            privacy@yumidocs.com
          </a>.
        </p>
        <p>
          You can also exercise your rights at any time by contacting us at 20-22 Wenlock Rd, London, England, N1 7GU or <a
            href="mailto:privacy@yumidocs.com"
            class="email"
          >
            privacy@yumidocs.com
          </a>{' '}
          .
        </p>
        <h2 id="access-to-information">10. Access to information</h2>
        <p>
          The Act gives you the right to access information held about you. Your right of access can be exercised in accordance with the
          Act. Any access request may be subject to a fee of £10 to meet our costs in providing you with details of the information we hold
          about you.
        </p>
        <h2 id="changes-to-our-privacy-policy">11. Changes to our Privacy Policy</h2>
        <p>
          Any changes we may make to our Privacy Policy in the future will be posted on this page and, where appropriate, notified to you by
          e-mail. Please check back frequently to see any updates or changes to our Privacy Policy.
        </p>
      </div>
    </section>
  )
}

export default PrivacyPolicy
