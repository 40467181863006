const Icon = (props) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 489.383 489.383" {...props}>
    <g>
      <path d="M244.52,142.083c-2.4,0-4.3,1.9-4.3,4.3l0,0l0,0c0,2.4,1.9,4.3,4.3,4.3c2.4,0,4.3-1.9,4.3-4.3l0,0l0,0
			C248.82,143.983,246.92,142.083,244.52,142.083z" />
      <circle cx="244.52" cy="159.083" r="4.3" />
      <circle cx="244.52" cy="171.683" r="4.3" />
      <path d="M244.52,112.783c2.4,0,4.3-1.9,4.3-4.3s-1.9-4.3-4.3-4.3s-4.3,1.9-4.3,4.3C240.22,110.883,242.12,112.783,244.52,112.783z
			" />
      <circle cx="244.52" cy="121.083" r="4.3" />
      <circle cx="244.52" cy="133.783" r="4.3" />
      <circle cx="244.52" cy="368.283" r="4.3" />
      <circle cx="244.52" cy="380.883" r="4.3" />
      <path d="M244.52,321.983c2.4,0,4.3-1.9,4.3-4.3s-1.9-4.3-4.3-4.3c-2.4,0-4.3,1.9-4.3,4.3
			C240.12,320.083,242.12,321.983,244.52,321.983z" />
      <circle cx="244.52" cy="330.283" r="4.3" />
      <circle cx="244.52" cy="342.983" r="4.3" />
      <path d="M244.52,359.983c2.4,0,4.3-1.9,4.3-4.3l0,0l0,0c0-2.4-1.9-4.3-4.3-4.3c-2.4,0-4.3,1.9-4.3,4.3l0,0l0,0
			C240.12,357.983,242.12,359.983,244.52,359.983z" />
      <path d="M343.12,295.083L343.12,295.083L343.12,295.083c-2.1-1.1-4.7-0.2-5.8,1.9c-1.1,2.1-0.2,4.7,1.9,5.8l0,0l0,0
			c1.9,0.9,4.1,0.4,5.4-1.2c0.2-0.2,0.3-0.5,0.5-0.7c0.1-0.3,0.2-0.5,0.3-0.8C345.92,298.183,345.02,295.983,343.12,295.083z" />
      <circle cx="352.42" cy="304.683" r="4.3" />
      <circle cx="363.72" cy="310.383" r="4.3" />
      <path d="M309.32,277.883c-2.1-1.1-4.7-0.2-5.8,1.9c-1.1,2.1-0.2,4.7,1.9,5.8c2.1,1.1,4.7,0.2,5.8-1.9
			C312.32,281.583,311.42,278.983,309.32,277.883z" />
      <circle cx="318.62" cy="287.483" r="4.3" />
      <circle cx="329.92" cy="293.183" r="4.3" />
      <circle cx="136.52" cy="304.683" r="4.3" />
      <circle cx="125.22" cy="310.383" r="4.3" />
      <path d="M179.62,277.883c-2.1,1.1-3,3.7-1.9,5.8c1.1,2.1,3.7,3,5.8,1.9s3-3.7,1.9-5.8
			C184.42,277.583,181.82,276.783,179.62,277.883z" />
      <circle cx="170.32" cy="287.483" r="4.3" />
      <circle cx="159.02" cy="293.183" r="4.3" />
      <path d="M145.82,295.083L145.82,295.083L145.82,295.083c-2.1,1.1-3,3.7-1.9,5.8c1.1,2.1,3.7,3,5.8,1.9l0,0l0,0
			c2.1-1.1,3-3.7,1.9-5.8S147.92,293.983,145.82,295.083z" />
      <circle cx="136.52" cy="184.783" r="4.3" />
      <circle cx="125.22" cy="179.083" r="4.3" />
      <path d="M183.62,203.883c-2.1-1.1-4.7-0.2-5.8,1.9c-1.1,2.1-0.2,4.7,1.9,5.8c2.1,1.1,4.7,0.2,5.8-1.9
			C186.62,207.583,185.72,204.983,183.62,203.883z" />
      <circle cx="170.32" cy="201.983" r="4.3" />
      <circle cx="159.12" cy="196.283" r="4.3" />
      <path d="M149.82,186.683L149.82,186.683L149.82,186.683c-1.9-1-4.1-0.4-5.4,1.2c-0.2,0.2-0.3,0.5-0.5,0.7
			c-0.1,0.3-0.2,0.5-0.3,0.8c-0.5,1.9,0.3,4.1,2.2,5l0,0l0,0c2.1,1.1,4.7,0.2,5.8-1.9C152.72,190.383,151.92,187.783,149.82,186.683
			z" />
      <path d="M339.22,186.683L339.22,186.683L339.22,186.683c-2.1,1.1-3,3.7-1.9,5.8s3.7,3,5.8,1.9l0,0l0,0c2.1-1.1,3-3.7,1.9-5.8
			C344.02,186.383,341.42,185.583,339.22,186.683z" />
      <circle cx="352.52" cy="184.783" r="4.3" />
      <circle cx="363.72" cy="179.083" r="4.3" />
      <path d="M305.42,203.883c-2.1,1.1-3,3.7-1.9,5.8s3.7,3,5.8,1.9c2.1-1.1,3-3.7,1.9-5.8
			C310.12,203.683,307.52,202.783,305.42,203.883z" />
      <circle cx="318.62" cy="201.983" r="4.3" />
      <circle cx="329.92" cy="196.283" r="4.3" />
      <path d="M394.12,304.683c1.3,4.6,2.5,5.8,3.4,5.9c0.8,5.4,5,12.3,11.9,14.8c2.8,1,5.9,1,8.7,0c6.8-2.4,11.1-9.3,11.9-14.8
			c0.9-0.1,2.1-1.4,3.4-5.9c1.8-6.3-0.1-7.2-1.7-7c0.3-0.9,0.5-1.7,0.7-2.6c2.7-16.2-5.3-16.7-5.3-16.7s-1.3-2.5-4.8-4.5
			c-2.3-1.4-5.6-2.5-9.9-2.1c-1.4,0.1-2.7,0.3-3.9,0.7l0,0c-1.6,0.5-3,1.3-4.3,2.2c-1.6,1-3.1,2.3-4.4,3.7c-2.1,2.2-4,4.9-4.8,8.4
			c-0.7,2.6-0.5,5.3,0,8.2l0,0c0.2,0.9,0.4,1.7,0.7,2.6C394.22,297.483,392.32,298.383,394.12,304.683z" />
      <path d="M445.32,331.783c-9.7-2.5-17.6-8-17.6-8l-6.1,19.4l-1.2,3.7v-0.1l-1,3.1l-3.2-9.2c8-11.1-1.6-11-2.1-11s-10.1-0.1-2.1,11
			l-3.2,9.2l-1-3.1v0.1l-1.2-3.7l-6.1-19.4c0,0-7.9,5.5-17.6,8c-7.2,1.8-7.6,10.2-7.3,14.3c0,0,0.4,5.6,0.8,8.1
			c0,0,14.1,9.2,37.8,9.2c23.6,0,37.8-9.2,37.8-9.2c0.4-2.5,0.8-8.1,0.8-8.1C452.92,341.983,452.52,333.683,445.32,331.783z" />
      <path d="M397.52,164.883c0.8,5.4,5,12.3,11.9,14.8c2.8,1,5.9,1,8.7,0c6.8-2.4,11.1-9.3,11.9-14.8c0.9-0.1,2.1-1.4,3.4-5.9
			c1.8-6.3-0.1-7.2-1.7-7c0.3-0.9,0.5-1.7,0.7-2.6c2.7-16.2-5.3-16.7-5.3-16.7s-1.3-2.5-4.8-4.5c-2.3-1.4-5.6-2.5-9.9-2.1
			c-1.4,0.1-2.7,0.3-3.9,0.7l0,0c-1.6,0.5-3,1.3-4.3,2.2c-1.6,1-3.1,2.3-4.4,3.7c-2.1,2.2-4,4.9-4.8,8.4c-0.7,2.6-0.5,5.3,0,8.2l0,0
			c0.2,0.9,0.4,1.7,0.7,2.6c-1.6-0.1-3.5,0.8-1.7,7C395.42,163.483,396.62,164.783,397.52,164.883z" />
      <path d="M376.22,208.483c0,0,14.1,9.2,37.8,9.2c23.6,0,37.8-9.2,37.8-9.2c0.4-2.5,0.8-8.1,0.8-8.1c0.3-4.1-0.1-12.5-7.3-14.3
			c-9.7-2.5-17.6-8-17.6-8l-6.1,19.4l-1.2,3.7v-0.1l-1,3.1l-3.2-9.2c8-11.1-1.6-11-2.1-11s-10.1-0.1-2.1,11l-3.2,9.2l-1-3.1v0.1
			l-1.2-3.7l-6.1-19.4c0,0-7.9,5.5-17.6,8c-7.2,1.8-7.6,10.2-7.3,14.3C375.42,200.483,375.82,206.083,376.22,208.483z" />
      <path d="M55.12,304.683c1.3,4.6,2.5,5.8,3.4,5.9c0.8,5.4,5,12.3,11.9,14.8c2.8,1,5.9,1,8.7,0c6.8-2.4,11.1-9.3,11.9-14.8
			c0.9-0.1,2.1-1.4,3.4-5.9c1.8-6.3-0.1-7.2-1.7-7c0.3-0.9,0.5-1.7,0.7-2.6c2.7-16.2-5.3-16.7-5.3-16.7s-1.3-2.5-4.8-4.5
			c-2.3-1.4-5.6-2.5-9.9-2.1c-1.4,0.1-2.7,0.3-3.9,0.7l0,0c-1.6,0.5-3,1.3-4.3,2.2c-1.6,1-3.1,2.3-4.4,3.7c-2.1,2.2-4,4.9-4.8,8.4
			c-0.7,2.6-0.5,5.3,0,8.2l0,0c0.2,0.9,0.4,1.7,0.7,2.6C55.22,297.483,53.32,298.383,55.12,304.683z" />
      <path d="M106.32,331.783c-9.7-2.5-17.6-8-17.6-8l-6.1,19.4l-1.2,3.7v-0.1l-1,3.1l-3.2-9.2c8-11.1-1.6-11-2.1-11s-10.1-0.1-2.1,11
			l-3.2,9.2l-1-3.1v0.1l-1.2-3.7l-6.1-19.4c0,0-7.9,5.5-17.6,8c-7.2,1.8-7.6,10.2-7.3,14.3c0,0,0.4,5.6,0.8,8.1
			c0,0,14.1,9.2,37.8,9.2c23.6,0,37.8-9.2,37.8-9.2c0.4-2.5,0.8-8.1,0.8-8.1C113.92,341.983,113.52,333.683,106.32,331.783z" />
      <path d="M58.52,164.883c0.8,5.4,5,12.3,11.9,14.8c2.8,1,5.9,1,8.7,0c6.8-2.4,11.1-9.3,11.9-14.8c0.9-0.1,2.1-1.4,3.4-5.9
			c1.8-6.3-0.1-7.2-1.7-7c0.3-0.9,0.5-1.7,0.7-2.6c2.7-16.2-5.3-16.7-5.3-16.7s-1.3-2.5-4.8-4.5c-2.3-1.4-5.6-2.5-9.9-2.1
			c-1.4,0.1-2.7,0.3-3.9,0.7l0,0c-1.6,0.5-3,1.3-4.3,2.2c-1.6,1-3.1,2.3-4.4,3.7c-2.1,2.2-4,4.9-4.8,8.4c-0.7,2.6-0.5,5.3,0,8.2l0,0
			c0.2,0.9,0.4,1.7,0.7,2.6c-1.6-0.1-3.5,0.8-1.7,7C56.32,163.483,57.62,164.783,58.52,164.883z" />
      <path d="M106.32,186.083c-9.7-2.5-17.6-8-17.6-8l-6.1,19.4l-1.2,3.7v-0.1l-1,3.1l-3.2-9.2c8-11.1-1.6-11-2.1-11s-10.1-0.1-2.1,11
			l-3.2,9.2l-1-3.1v0.1l-1.2-3.7l-6.1-19.4c0,0-7.9,5.5-17.6,8c-7.2,1.8-7.6,10.2-7.3,14.3c0,0,0.4,5.6,0.8,8.1
			c0,0,14.1,9.2,37.8,9.2c23.6,0,37.8-9.2,37.8-9.2c0.4-2.5,0.8-8.1,0.8-8.1C113.92,196.283,113.52,187.983,106.32,186.083z" />
      <path d="M224.42,430.683c1.3,4.6,2.5,5.8,3.4,5.9c0.8,5.4,5,12.3,11.9,14.8c2.8,1,5.9,1,8.7,0c6.8-2.4,11.1-9.3,11.9-14.8
			c0.9-0.1,2.1-1.4,3.4-5.9c1.8-6.3-0.1-7.2-1.7-7c0.3-0.9,0.5-1.7,0.7-2.6c2.7-16.2-5.3-16.7-5.3-16.7s-1.3-2.5-4.8-4.5
			c-2.3-1.4-5.6-2.5-9.9-2.1c-1.4,0.1-2.7,0.3-3.9,0.7l0,0c-1.6,0.5-3,1.3-4.3,2.2c-1.6,1-3.1,2.3-4.4,3.7c-2.1,2.2-4,4.9-4.8,8.4
			c-0.7,2.6-0.5,5.3,0,8.2l0,0c0.2,0.9,0.4,1.7,0.7,2.6C224.52,423.483,222.62,424.383,224.42,430.683z" />
      <path d="M275.72,457.783c-9.7-2.5-17.6-8-17.6-8l-6.1,19.4l-1.2,3.7v-0.1l-1,3.1l-3.2-9.2c8-11.1-1.6-11-2.1-11s-10.1-0.1-2.1,11
			l-3.2,9.2l-1-3.1v0.1l-1.2-3.7l-6.1-19.4c0,0-7.9,5.5-17.6,8c-7.2,1.8-7.6,10.2-7.3,14.3c0,0,0.4,5.6,0.8,8.1
			c0,0,14.1,9.2,37.8,9.2c23.6,0,37.8-9.2,37.8-9.2c0.4-2.5,0.8-8.1,0.8-8.1C283.22,467.983,282.92,459.683,275.72,457.783z" />
      <path d="M227.82,38.883c0.8,5.4,5,12.3,11.9,14.8c2.8,1,5.9,1,8.7,0c6.8-2.4,11.1-9.3,11.9-14.8c0.9-0.1,2.1-1.4,3.4-5.9
			c1.8-6.3-0.1-7.2-1.7-7c0.3-0.9,0.5-1.7,0.7-2.6c2.7-16.2-5.3-16.7-5.3-16.7s-1.3-2.5-4.8-4.5c-2.3-1.4-5.6-2.5-9.9-2.1
			c-1.4,0.1-2.7,0.3-3.9,0.7l0,0c-1.6,0.5-3,1.3-4.3,2.2c-1.6,1-3.1,2.3-4.4,3.7c-2.1,2.2-4,4.9-4.8,8.4c-0.7,2.6-0.5,5.3,0,8.2l0,0
			c0.2,0.9,0.4,1.7,0.7,2.6c-1.6-0.1-3.5,0.8-1.7,7C225.72,37.483,226.92,38.783,227.82,38.883z" />
      <path d="M206.62,82.483c0,0,14.1,9.2,37.8,9.2c23.6,0,37.8-9.2,37.8-9.2c0.4-2.5,0.8-8.1,0.8-8.1c0.3-4.1-0.1-12.5-7.3-14.3
			c-9.7-2.5-17.6-8-17.6-8l-6.1,19.4l-1.2,3.7v-0.1l-1,3.1l-3.2-9.2c8-11.1-1.6-11-2.1-11s-10.1-0.1-2.1,11l-3.2,9.2l-1-3.1v0.1
			l-1.2-3.7l-6.1-19.4c0,0-7.9,5.5-17.6,8c-7.2,1.8-7.6,10.2-7.3,14.3C205.82,74.483,206.22,80.083,206.62,82.483z" />
      <path d="M209.32,210.983c-17.9,17.9-18.6,46.6-2,65.3l0.5,0.4c-1.8,3.9-4.9,8.7-9.8,11.1c-1.6,0.8-1.3,3.2,0.5,3.5
			c5.4,0.8,13.1-0.1,20.3-5.5l0.1,0.1c18.3,10.8,42.2,8.3,57.9-7.4c18.6-18.6,18.6-48.8,0-67.5S228.02,192.283,209.32,210.983z
			 M222.52,250.883c-3.4,0-6.2-2.8-6.2-6.2s2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2S225.92,250.883,222.52,250.883z M243.12,250.883
			c-3.4,0-6.2-2.8-6.2-6.2s2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2S246.52,250.883,243.12,250.883z M263.62,250.883c-3.4,0-6.2-2.8-6.2-6.2
			s2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2S267.02,250.883,263.62,250.883z" />
    </g>
  </svg>
)

export default Icon
