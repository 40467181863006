import HeadlineTop from '../../common/HeadlineTop/HeadlineTop'
import './ContractsAsData.scss'

const ContractsAsData = () => {
  const list = [
    {
      icon: null,
      title: (
        <span>
          No PDFs opened<br />Data available immediately
        </span>
      ),
      description:
        'All commercial and legal terms are available immediately without opening any PDF files. No manual or AI extraction is required. Use it in notifications, dashboards, automation, integration, or APIs.',
    },

    {
      icon: null,
      title: 'Accurate Automation',
      description:
        'Enable company-wide business processes with always-accurate, real-time contractual data. Mitigate risks. Automate the tedious data entry away.',
    },

    {
      icon: null,
      title: 'Ecosystem ++',
      description:
        "All YumiDocs' contractual parties access the terms as structured data. Create synergies and expand automation beyond the traditional organizational boundaries by sharing trustworthy, verifiable data with your partners.",
    },
  ]

  return (
    <section className="ContractsAsData" id="ContractsAsData">
      <HeadlineTop>Contracts as Data</HeadlineTop>
      <p className="description">Data changes everything. Here's the new world where legal data is available directly to all parties.</p>
      <article className="ContractsAsData__content">
        {list.map((item, key) => (
          <div key={key} className="ContractsAsData__item">
            <div className="top">
              <div className="icon">{item.icon}</div>
              <div className="title">
                <h3>{item.title}</h3>
              </div>
            </div>
            <p>{item.description}</p>
          </div>
        ))}
      </article>
    </section>
  )
}

export default ContractsAsData
