const Icon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32" {...props}><g>
      <path strokeWidth="0.6" fill={props.color} stroke={props.color} d="M16,31.36c-1.577,0-2.86-1.283-2.86-2.86c0-1.455,1.092-2.659,2.499-2.837l-0.013-4.662
	c0-0.905-0.736-1.641-1.64-1.641H7.5c-3.783,0-6.86-3.078-6.86-6.86S3.717,5.64,7.5,5.64h1.239c1.63-3.09,4.775-5,8.261-5
	c5.04,0,9.163,4.005,9.354,9H26.5c2.68,0,4.86,2.18,4.86,4.86s-2.181,4.86-4.86,4.86l-2.498,0.014l-0.004-0.721l2.5-0.014
	c2.285,0,4.142-1.857,4.142-4.14s-1.856-4.14-4.14-4.14H26c-0.199,0-0.36-0.161-0.36-0.36c0-4.764-3.876-8.64-8.64-8.64
	c-3.285,0-6.242,1.84-7.719,4.801c-0.061,0.123-0.185,0.2-0.322,0.2H7.5c-3.386,0-6.14,2.754-6.14,6.14s2.754,6.14,6.14,6.14h6.486
	c1.301,0,2.36,1.059,2.36,2.36l0.013,4.662c1.409,0.178,2.501,1.383,2.501,2.838C18.86,30.077,17.577,31.36,16,31.36z M16,26.36
	c-1.18,0-2.14,0.96-2.14,2.14s0.96,2.14,2.14,2.14s2.14-0.96,2.14-2.14S17.18,26.36,16,26.36z M26,28.36
	c-0.062,0-0.122-0.016-0.178-0.047C25.71,28.249,25.64,28.13,25.64,28v-2.64H22c-1.302,0-2.36-1.059-2.36-2.36v-5.662
	c-1.407-0.178-2.5-1.383-2.5-2.838c0-1.577,1.283-2.86,2.86-2.86s2.86,1.283,2.86,2.86c0,1.455-1.093,2.66-2.5,2.838V23
	c0,0.904,0.735,1.64,1.64,1.64h3.64V22c0-0.13,0.07-0.249,0.183-0.313c0.114-0.064,0.25-0.062,0.363,0.005l5,3
	c0.108,0.065,0.175,0.183,0.175,0.309s-0.066,0.243-0.175,0.309l-5,3C26.128,28.343,26.064,28.36,26,28.36z M26.36,22.636v4.729
	L30.3,25L26.36,22.636z M20,12.36c-1.18,0-2.14,0.96-2.14,2.14s0.96,2.14,2.14,2.14s2.14-0.96,2.14-2.14S21.18,12.36,20,12.36z
	 M6,27.86H1c-0.199,0-0.36-0.161-0.36-0.36v-5c0-0.199,0.161-0.36,0.36-0.36h5c0.199,0,0.36,0.161,0.36,0.36v2.14H10
	c0.904,0,1.64-0.735,1.64-1.64v-2h0.72v2c0,1.302-1.059,2.36-2.36,2.36H6.36v2.14C6.36,27.699,6.199,27.86,6,27.86z M1.36,27.14
	h4.28v-4.28H1.36V27.14z"/></g></svg>
  )
}

export default Icon
