const Icon = (props) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="612px"
    height="612px"
    viewBox="0 0 612 612"
    {...props}
  >
    <g>
      <path d="M164.771,335.424c9.548,0,18.537-2.323,26.512-6.359l43.332,59.297c-10.428,10.619-16.877,25.159-16.877,41.22
			c0,32.498,26.345,58.848,58.848,58.848c32.498,0,58.848-26.345,58.848-58.848c0-4.781-0.631-9.4-1.707-13.852l87.382-37.073
			c10.438,16.442,28.755,27.387,49.673,27.387c32.498,0,58.848-26.345,58.848-58.848c0-23.395-13.689-43.543-33.464-53.029
			l59.497-204.528l27.445,7.985l-18.169-74.081l-55.08,52.771l27.439,7.985l-59.503,204.503c-2.31-0.277-4.643-0.459-7.028-0.459
			c-32.498,0-58.848,26.345-58.848,58.848c0,4.781,0.631,9.4,1.707,13.852l-87.382,37.073
			c-10.438-16.442-28.755-27.387-49.673-27.387c-9.548,0-18.537,2.324-26.512,6.359l-43.333-59.297
			c10.433-10.619,16.882-25.159,16.882-41.22c0-32.498-26.345-58.848-58.848-58.848s-58.848,26.345-58.848,58.848
			S132.269,335.424,164.771,335.424z" />
      <path d="M41.19,500.53h23.696c9.051,0,16.395-7.34,16.395-16.396c0-9.051-7.339-16.395-16.395-16.395H41.19v-73.22h23.696
			c9.051,0,16.395-7.34,16.395-16.396c0-9.051-7.339-16.395-16.395-16.395H41.19v-73.215h23.696c9.051,0,16.395-7.339,16.395-16.395
			c0-9.051-7.339-16.395-16.395-16.395H41.19v-73.22h23.696c9.051,0,16.395-7.339,16.395-16.395s-7.339-16.395-16.395-16.395H41.19
			V76.5h23.696c9.056,0,16.395-7.339,16.395-16.395S73.942,43.71,64.886,43.71H41.19V0H0v570.81V612l0,0h41.19l0,0H612v-41.19H41.19
			V500.53z" />
    </g>
  </svg>
)

export default Icon
