import React from 'react'
import './Benefits.scss'
import ArrowDown from '../../Icons/ArrowDown'
import ArrowUp from '../../Icons/ArrowUp'

class CollapsibleBenefit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true,
      benefit: props.benefit,
    }
  }
  toggle() {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    return (
      <div className="Benefits__items__card" key="card">
        <div className="Benefits__items__card__top">
          <div className="Benefits__items__card__headline">
            <div className="imageContainer">{this.state.benefit.logo}</div>
            <p key="headline" id="headline">
              {this.state.benefit.headline}
            </p>
          </div>
          <div className="Benefits__items__card__nav">
            {this.state.open ? (
              <ArrowDown width="40" height="40" onClick={() => this.toggle()} />
            ) : (
              <ArrowUp width="40" height="40" onClick={() => this.toggle()} />
            )}
          </div>
        </div>
        <div className={'Benefits__items__card__details ' + (this.state.open ? 'collapsed' : '')} key="details">
          {this.state.benefit.description.map((e, i) => React.cloneElement(e, { key: i.toString() })) || ''}
        </div>
      </div>
    )
  }
}

export default CollapsibleBenefit
