import { Link } from 'react-router-dom'
import LogoIcon from '../../Icons/Logo'
import ArrowDown from '../../Icons/ArrowDown'
import ArrowUp from '../../Icons/ArrowUp'
import './Header.scss'
import Segment from '../../common/SegmentIO'

const Header = () => {
  const menuItems = [
    {
      label: 'Platform',
      link: '/platform',
    },
    {
      label: 'Pricing',
      link: '/pricing',
    },
    // {
    //   label: 'Case Studies',
    //   link: '/casestudy',
    // },
    {
      label: 'Case studies',
      children: [
        {
          label: 'Accelerate sales cycle',
          link: '/casestudy/accelerated-sales',
        },
        {
          label: 'Repaper existing agreements',
          link: '/casestudy/repapering',
        },
      ],
    },
    {
      label: 'News',
      link: 'https://yumidocs.medium.com/',
    },
    {
      label: 'Sign In',
      className: 'signin',
      link: 'https://app.yumidocs.com',
    },
    {
      label: 'Start free',
      className: 'startfree',
      link: 'https://app.yumidocs.com',
    },
  ]
  const trackingFun = (label) => Segment.getAnalytics().then((ana) => ana.track(label, { section: 'Menu', title: label }))

  return (
    <header className="layout__header">
      <div className="main-header">
        <div className="logo">
          <Link to="">
            <LogoIcon />
          </Link>
        </div>
        <div className="menu">
          <ul>
            <input type="checkbox" id="checkbox_toggle" />
            <label htmlFor='checkbox_toggle' className="menuToggle">&#9776;</label>

            {menuItems.map((e, idx) => (
              <li key={idx.toString() + 'li1'}  className="menuItem">
                <a
                  className={e.className ? e.className : null}
                  target={e.link && e.link.startsWith('http') ? '_blank' : '_self'}
                  rel="noopener noreferrer"
                  key={idx}
                  href={e.link}
                  onClick={() => trackingFun(e.label)}
                >
                  {e.label}
                  {e.children ? <ArrowDown width="20" className="arrowDown" /> : null}
                  {e.children ? <ArrowUp width="20" className="arrowUp" /> : null}
                </a>

                {e.children ? (
                  <ul className="dropdown">
                    {e.children ? (
                      e.children.map((ch, i) => {
                        return (
                          <li key={i.toString() + 'li2'} className="menuItem">
                            <a
                              className={ch.className ? ch.className : null}
                              target={ch.link && ch.link.startsWith('http') ? '_blank' : '_self'}
                              rel="noopener noreferrer"
                              key={i}
                              href={ch.link}
                              onClick={() => trackingFun(ch.label)}
                            >
                              {ch.label}
                            </a>
                          </li>
                        )
                      })
                    ) : null}
                  </ul>
                ) : null}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header
