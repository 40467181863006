const Icon = (props) => (
  <svg width="316" height="316" viewBox="0 0 316 316" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_946_309)">
      <path d="M263.89 8.74316C260.851 8.74316 258.387 11.2072 258.387 14.2462V271.853C258.387 272.707 258.586 273.549 258.968 274.314L273.797 303.968C274.729 305.832 276.634 307.01 278.719 307.01C280.803 307.01 282.708 305.832 283.641 303.968L298.469 274.314C298.851 273.549 299.05 272.708 299.05 271.853V14.2462C299.05 11.2072 296.586 8.74316 293.547 8.74316H263.89ZM288.044 270.552L278.719 289.201L269.393 270.552V19.7492H288.044V270.552Z" />
      <path d="M304.746 48.0372V135.826C304.746 138.865 307.21 141.329 310.249 141.329C313.288 141.329 315.752 138.865 315.752 135.826V48.0372C315.752 44.9982 313.288 42.5342 310.249 42.5342C307.21 42.5342 304.746 44.9982 304.746 48.0372Z" />
      <path d="M186.526 233.641H47.204C44.165 233.641 41.701 236.106 41.701 239.145C41.701 242.184 44.165 244.647 47.204 244.647H186.526C189.565 244.647 192.029 242.184 192.029 239.145C192.029 236.106 189.565 233.641 186.526 233.641Z" />
      <path d="M186.526 264.068H47.204C44.165 264.068 41.701 266.532 41.701 269.571C41.701 272.61 44.165 275.074 47.204 275.074H186.526C189.565 275.074 192.029 272.61 192.029 269.571C192.029 266.532 189.565 264.068 186.526 264.068Z" />
      <path d="M186.526 129.295H47.204C44.165 129.295 41.701 131.759 41.701 134.798C41.701 137.837 44.165 140.301 47.204 140.301H186.526C189.565 140.301 192.029 137.837 192.029 134.798C192.029 131.759 189.565 129.295 186.526 129.295Z" />
      <path d="M47.204 104.959H129.913C132.952 104.959 135.416 102.494 135.416 99.4551C135.416 96.4161 132.952 93.9531 129.913 93.9531H47.204C44.165 93.9531 41.701 96.4161 41.701 99.4551C41.701 102.494 44.165 104.959 47.204 104.959Z" />
      <path d="M47.204 69.6154H129.913C132.952 69.6154 135.416 67.1524 135.416 64.1134C135.416 61.0744 132.952 58.6094 129.913 58.6094H47.204C44.165 58.6094 41.701 61.0744 41.701 64.1134C41.701 67.1524 44.165 69.6154 47.204 69.6154Z" />
      <path d="M5.488 306.569H228.242C231.273 306.569 233.73 304.112 233.73 301.081V87.8436C233.73 87.8356 233.729 87.8286 233.729 87.8216C233.728 87.6446 233.719 87.4686 233.702 87.2966C233.689 87.1626 233.666 87.0316 233.643 86.9006C233.635 86.8556 233.631 86.8096 233.623 86.7646C233.592 86.6106 233.552 86.4566 233.508 86.3056C233.502 86.2856 233.498 86.2656 233.492 86.2446C233.445 86.0946 233.39 85.9456 233.332 85.7986C233.324 85.7796 233.318 85.7596 233.311 85.7406C233.254 85.6026 233.189 85.4696 233.12 85.3386C233.106 85.3116 233.095 85.2816 233.08 85.2546C233.016 85.1356 232.944 85.0206 232.872 84.9056C232.848 84.8686 232.827 84.8286 232.803 84.7926C232.734 84.6896 232.657 84.5906 232.58 84.4916C232.547 84.4486 232.516 84.4026 232.482 84.3596C232.387 84.2446 232.285 84.1346 232.179 84.0266C232.159 84.0056 232.143 83.9836 232.122 83.9636L158.948 10.7906C158.821 10.6636 158.688 10.5446 158.551 10.4316C158.515 10.4026 158.476 10.3766 158.44 10.3486C158.335 10.2666 158.229 10.1846 158.12 10.1116C158.088 10.0906 158.053 10.0716 158.02 10.0506C157.902 9.97462 157.782 9.90062 157.659 9.83362C157.637 9.82162 157.612 9.81262 157.59 9.80062C157.453 9.72962 157.315 9.66162 157.173 9.60362C157.157 9.59662 157.141 9.59162 157.126 9.58562C156.976 9.52462 156.823 9.46962 156.669 9.42162C156.651 9.41662 156.634 9.41362 156.616 9.40862C156.463 9.36262 156.307 9.32262 156.149 9.29062C156.106 9.28262 156.06 9.27862 156.017 9.27062C155.884 9.24762 155.751 9.22362 155.617 9.21062C155.436 9.19162 155.253 9.18262 155.069 9.18262H5.488C2.457 9.18362 0 11.6406 0 14.6716V301.081C0 304.112 2.457 306.569 5.488 306.569ZM214.995 82.3576L160.561 82.3636L160.558 27.9226L214.995 82.3576ZM10.976 20.1606H149.581L149.585 87.8526C149.585 89.3086 150.163 90.7046 151.192 91.7336C152.221 92.7616 153.618 93.3396 155.073 93.3396L222.755 93.3316V295.593H10.976V20.1606Z" />
      <path d="M69.1214 164H46.8786C46.3934 164 46 166.464 46 169.501C46 172.538 46.3934 175 46.8786 175H69.1214C69.6066 175 70 172.538 70 169.501C70 166.464 69.6066 164 69.1214 164Z" />
      <path d="M69.1214 210H46.8786C46.3934 210 46 212.464 46 215.501C46 218.538 46.3934 221 46.8786 221H69.1214C69.6066 221 70 218.538 70 215.501C70 212.464 69.6066 210 69.1214 210Z" />
      <path d="M188.121 164H165.879C165.393 164 165 166.464 165 169.501C165 172.538 165.393 175 165.879 175H188.121C188.607 175 189 172.538 189 169.501C189 166.464 188.607 164 188.121 164Z" />
      <path d="M188.121 210H165.879C165.393 210 165 212.464 165 215.501C165 218.538 165.393 221 165.879 221H188.121C188.607 221 189 218.538 189 215.501C189 212.464 188.607 210 188.121 210Z" />
      <path d="M182 166.087V218.913C182 220.066 184.464 221 187.501 221C190.538 221 193 220.066 193 218.913V166.087C193 164.934 190.538 164 187.501 164C184.464 164 182 164.934 182 166.087Z" />
      <path d="M42 166.087V218.913C42 220.066 44.4637 221 47.501 221C50.5383 221 53 220.066 53 218.913V166.087C53 164.934 50.5383 164 47.501 164C44.4637 164 42 164.934 42 166.087Z" />
    </g>
    <defs>
      <clipPath id="clip0_946_309">
        <rect width="315.752" height="315.752" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Icon
