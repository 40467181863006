import './TermsOfService.scss'

const TermsOfService = () => {
  return (
    <section className="TermsOfService">
      <div className="TermsOfService__content">
        <h1 id="terms-of-service">Terms of service</h1>
        <p>
          Last updated on June 25<sup>th</sup>, 2021.
        </p>
        <p>
          <a href="http://www.yumidocs.com">yumidocs.com</a> website with all applications, services, API endpoints or application plug-ins
          that connect to the YumiDocs will be collectively referred to as the “YumiDocs” are the property of Yumi Ltd.
        </p>

        <p>
          Yumi Ltd is a company registered in England and Wales, UK with the office at 20-22 WENLOCK RD, London, England, N1 7GU, (the
          “Company” “We”, “YumiDocs”,“Us” and “Our”).
        </p>

        <p>
          By visiting or using YumiDocs, you agree to follow and be bound by these terms of service (the “Terms of Service”) and agree to
          comply with all applicable laws and regulations. In these Terms of Service, the words “you” and “your” refer to each customer,
          YumiDocs visitor or user, “we”, “us” and “our” refer to Yumi Ltd. “Services” refers to all services and functionalities available
          to you through the YumiDocs.
        </p>
        <p>
          Please also review our Privacy Policy, which is incorporated herein by reference which governs all the data collected by YumiDocs.
        </p>
        <p>
          It is your responsibility to review these Terms of Service and the Privacy Policy periodically. If at any time you do not agree
          with or find these Terms of Service or the Privacy Policy unacceptable, please do not use this Site or any Applications. We may
          revise these Terms of Service at any time without notice to you. If you have any questions about these Terms of Service, please
          contact us at{' '}
          <a href="mailto:support@yumidocs.com" class="email">
            support@yumidocs.com
          </a>.
        </p>
        <h2 id="yumi-and-yumidocs.">1. Yumi and YumiDocs.</h2>
        <p>
          Yumi Ltd (“Yumi”) registered in London, United Kingdom is the legal owner of YumiDocs and operates the online portal YumiDocs
          accessible at yumidocs.com to provide companies and individuals an automated software solution for legal documents drafting,
          generation, negotiation, execution, amendment, storage and management.
        </p>
        <h2 id="yumi-is-not-a-law-firm.">2. Yumi is not a law firm.</h2>
        <p>
          Yumi and YumiDocs do not represent a substitute for legal services or legal advice. Yumi and YumiDocs do not review your contracts
          for legal sufficiency, draw legal conclusions, provide legal advice, opinions or recommendations about your legal rights, options,
          remedies, defenses, positions and selection of forms, strategies, or apply the law to the facts of your particular situation.
        </p>
        <p>Yumi is not a law firm and cannot perform services performed by an attorney.</p>
        <p>
          The documents available via YumiDocs may not fit your specific circumstances. You should make your own judgement on the
          suitability of any such materials to your or any third party’s circumstances, or obtain your own legal, tax, accounting or
          financial advice to review such materials before using them.
        </p>
        <p>
          You understand and agree that Yumi and YumiDocs are not a substitute for the advice or services of an attorney and no
          attorney-client relationship or privilege is created by subscribing or using YumiDocs.
        </p>
        <p>
          If, prior to purchasing a subscription to YumiDocs, you believe that YumiDocs gave you any legal advice, opinion or recommendation
          about your legal rights, remedies, defence, options, selection of forms or strategies, you will not proceed with this purchase,
          and any purchase that you do make will be null and void.
        </p>
        <p>
          Yumi cannot guarantee that all of the information on YumiDocs is complete and current. Yumi strives to keep its legal documents
          accurate, current and up to date. Furthermore, the legal information contained on YumiDocs is not legal advice and is not
          guaranteed to be correct, complete or up to date.
        </p>
        <p>
          Whilst we deny that any legal responsibility arises when you use the Platform or our Services, in the event we are wrong, we
          exclude all legal responsibility and costs for reliance placed by anyone on YumiDocs or Yumi.
        </p>
        <p>
          For legal, financial or tax advice, for solving specific problems, or for complex problems you should consult a licensed attorney
          in your jurisdiction.
        </p>
        <h2 id="account-information.">3. Account Information.</h2>
        <p>
          When you open an account to use or access certain portions of YumiDocs you must provide complete and accurate information as
          requested on the registration form. You may also be asked to provide a username and password. You are entirely responsible for
          maintaining the confidentiality of your password. You may not use a third party’s account, username or password at any time. You
          agree to notify Yumi immediately of any unauthorized use of your account, username or password. YumiDocs shall not be liable for
          any losses you incur as a result of someone else’s use of your account or password, either with or without your knowledge. You may
          be held liable for any losses incurred by Yumi, our affiliates, officers, directors, employees, consultants, agents and
          representatives due to someone else’s use of your account or password.
        </p>
        <p>
          In connection with the use of certain Yumi products or services, you may be asked to provide personal information in a
          questionnaire, application, form or similar document or service. This information will be protected pursuant to our Privacy
          Policy. In addition, you grant YumiDocs a worldwide, royalty-free, nonexclusive, and fully sublicensable license to use,
          distribute, reproduce, modify, publish and translate this personal information solely for the purpose of enabling your use of the
          applicable service. You may revoke this license and terminate rights held by Yumi at any time by removing the account that you
          have with YumiDocs.
        </p>
        <h2 id="ownership.">4. Ownership.</h2>
        <p>
          YumiDocs, and all the materials displayed, performed or available on, through or by using YumiDocs including, but not limited to,
          text, documents, reports, graphics, data, articles, photos, videos, images, illustrations, and so forth (all of the foregoing, the
          "YumiDocs Content"), are owned by or licensed to Yumi and are protected by copyright, trademark, patent and/or all the applicable
          intellectual property laws.
        </p>
        <p>
          Yumi does not sell, license, lease or otherwise provide any of the YumiDocs Content other than those specifically identified as
          being provided by Yumi. Any rights not expressly granted herein are reserved by Yumi Ltd.
        </p>
        <p>
          Except as explicitly stated in these Terms of Service, we reserve all rights in and title to Yumi for the YumiDocs Content and the
          compilation, collection, arrangement, or assembly of all YumiDocs Content. Any code, software code and digital content that Yumi
          creates, generates or displays in the Application is also protected and you may not copy or adapt such code without our explicit
          consent. Subject to your compliance with these Terms of Service, you are hereby granted a limited, nonexclusive, non-transferable,
          non-sublicensable, revocable license to access and use the YumiDocs and YumiDocs Content for your own use. Such license is subject
          to these Terms of Service and does not include any right to (i) sell, resell, or commercially use the YumiDocs or the YumiDocs
          Content, (ii) copy, reproduce, distribute, publicly perform, or publicly display the YumiDocs Content, except as expressly
          permitted by Yumi, (iii) modify YumiDocs or the YumiDocs Content, remove any proprietary rights notices or markings, or otherwise
          make any derivative uses of YumiDocs or the YumiDocs Content, or (iv) use YumiDocs or YumiDocs Content other than for their
          intended purposes.
        </p>
        <h2 id="integrations.">5. Integrations.</h2>
        <p>
          YumiDocs and YumiDocs Content may contain links to websites controlled by parties other than Yumi Inc (each a “Third Party Site”).
          Yumi works with a number of partners and affiliates whose sites are linked with YumiDocs. YumiDocs may also provide links to other
          citations or resources with whom it is not affiliated. YumiDocs is not responsible for and does not endorse or accept any
          responsibility for the availability, contents, products, services or use of any Third Party Site, any website accessed from a
          Third Party Site or any changes or updates to such sites. YumiDocs makes no guarantees about the content or quality of the
          products or services provided by such sites. YumiDocs is not responsible for webcasting or any other form of transmission received
          from any Third Party Site. YumiDocs is providing these links to you only as a convenience, and the inclusion of any link does not
          imply endorsement by Yumi of the Third Party Site, nor does it imply that Yumi sponsors, is affiliated or associated with,
          guarantees, or is legally authorized to use any trade name, registered trademark, logo, legal or official seal, or copyrighted
          symbol that may be reflected in the links. You acknowledge that you bear all risks associated with access to and use of content
          provided on a Third Party Site and agree that YumiDocs is not responsible for any loss or damage of any sort you may incur from
          dealing with a third party. You should contact the site administrator for the applicable Third Party Site if you have any concerns
          regarding such links or the content located on any such Third Party Site.
        </p>
        <h2 id="templates.">6. Templates.</h2>
        <p>
          On YumiDocs and through certain partners, we offer self-help “fill in the blank” forms and legal contract templates. You
          understand that your purchase, download, and/or- use of a form and template document is neither legal advice nor the practice of
          law, and that each form and any applicable instructions or guidance is not customized to your particular needs.
        </p>
        <p>
          YumiDocs grants you a limited, personal, non-exclusive, non-transferable license to use our forms (the “Templates”) for your own
          personal, internal business use, or if you are an attorney or professional, for your client. Except as otherwise provided, you
          acknowledge and agree that you have no right to modify, edit, copy, reproduce, create derivative works of, reverse engineer,
          alter, enhance or in any way exploit any of the Templates in any manner, except for modifications in filling out the Templates for
          your authorized use. You shall not remove any copyright notice from any Form.
        </p>
        <p>
          By ordering or downloading Templates, you agree that the Templates you purchase, or download may only be used by you for your
          personal or business use or used by you in connection with your client and may not be sold or redistributed without the express
          written consent of YumiDocs.
        </p>
        <h2 id="no-warranty.">7. No Warranty.</h2>
        <p>
          YumiDocs and YumiDocs Content with all materials, documents, templates or forms provided on or through your use of YumiDocs are
          provided on an “as is” and “as available” basis. To the fullest extent permitted by law, YumiDocs expressly disclaims all
          warranties of any kind, whether express or implied, including but not limited to implied warranties of merchantability, readiness,
          suitability, fitness for a particular purpose, title and non-infringement.
        </p>
        <p>
          YumiDocs makes no warranty that: (a) the site, applications, or the materials will meet your requirements; (b) the site,
          applications, or the materials will be available on an uninterrupted, timely, secure or error-free basis; (c) the results that may
          be obtained from the use of the site, applications, or any materials offered through the site or applications, will be accurate or
          reliable; or (d) the quality of any products, services, information or other material purchased or obtained by you through the
          site, applications, or in reliance on the materials will meet your expectations.
        </p>
        <p>
          Obtaining any materials through the use of the site or applications is done at your own discretion and at your own risk. YumiDocs
          shall have no responsibility for any damage to your computer system or loss of data that results from the download of any content,
          materials, information or software.
        </p>
        <h2 id="limitation-of-liability.">8. Limitation of Liability.</h2>
        <p>
          Except as prohibited by law, you will hold YumiDocs and its officers, directors, employees, and agents harmless for any indirect,
          punitive, special, incidental, or consequential damage, however it arises (including attorneys’ fees and all related costs and
          expenses of litigation and arbitration, or at trial or on appeal, if any, whether or not litigation or arbitration is instituted),
          whether in an action of contract, negligence, or other tortious action, or arising out of or in connection with this agreement,
          including without limitation any claim for personal injury or property damage, arising from this agreement and any violation by
          you of any federal, state, or local laws, statutes, rules, or regulations, even if YumiDocs has been previously advised of the
          possibility of such damage. Except as prohibited by law, if there is liability found on the part of YumiDocs, it will be limited
          to the amount paid for the products and/or services, and under no circumstances will there be consequential or punitive damages.
        </p>
        <p>
          Whilst we do not guarantee that YumiDocs is available through it will always be available or be uninterrupted or error free, we
          will use reasonable efforts consistent with prevailing industry standards to maintain YumiDocs in a manner which minimises errors
          and interruptions. We will perform updates to YumiDocs in a professional manner. The Platform may be temporarily unavailable for
          scheduled maintenance, for unscheduled emergency maintenance or because of other causes beyond our reasonable control. We will use
          reasonable efforts to communicate this via social media channels, email or on YumiDocs, and we will aim to provide (where
          possible) an estimated time by which YumiDocs will resume its normal service.
        </p>
        <h2 id="compliance-with-intellectual-property-laws.">9. Compliance with Intellectual Property Laws.</h2>
        <p>
          When accessing YumiDocs or using the YumiDocs legal document preparation Service, you agree to obey the law and you agree to
          respect the intellectual property rights of others. Your use of the YumiDocs is at all times governed by and subject to laws
          regarding copyright, trademark and other intellectual property ownership. You agree not to upload, download, display, perform,
          transmit or otherwise distribute any information or content in violation of any third party’s copyrights, trademarks or other
          intellectual property or proprietary rights. You agree to abide by laws regarding copyright ownership and use of intellectual
          property, and you shall be solely responsible for any violations of any relevant laws and for any infringements of third-party
          rights caused by any content you provide or transmit or that is provided or transmitted using your YumiDocs user account.
        </p>
        <p>
          YumiDocs has adopted a policy that provides for the immediate removal of any content, article or materials that have infringed on
          the rights of YumiDocs or of a third party or that violate intellectual property rights generally. YumiDocs has a policy to remove
          such infringing content or materials and investigate such allegations immediately.
        </p>
        <h2 id="inappropriate-content.">10. Inappropriate Content.</h2>
        <p>
          When accessing YumiDocs you agree not to upload, download, display, perform, transmit or otherwise distribute any content that:
          (i) is libellous, defamatory, obscene, pornographic, abusive or threatening; (b) advocates or encourages conduct that could
          constitute a criminal offense, give rise to civil liability or otherwise violate any applicable local, state, national or foreign
          law or regulation; or (c) advertises or otherwise solicits funds or is a solicitation for goods or services. YumiDocs reserves the
          right to terminate or delete such material from its servers. YumiDocs will cooperate fully with any law enforcement officials or
          agencies in the investigation of any violation of these Terms of Service or of any applicable laws.
        </p>
        <h2 id="governing-law.">11. Governing Law.</h2>
        <p>
          This Terms of Service agreement and any dispute or claim arising out of or in connection with it or its subject matter or
          formation (including non-contractual disputes or claims) are governed by the law of England and Wales. You irrevocably agree that
          the courts of England and Wales have exclusive jurisdiction to determine any dispute or claim that arises out of or in connection
          with this Terms of Use or its subject matter or formation (including non-contractual disputes or claims).
        </p>
        <p>
          These Terms of Service expressly exclude and disclaim the terms of the U.N. Convention on Contracts for the International Sale of
          Goods, which shall not apply to any transaction conducted through or otherwise involving YumiDocs or Yumi Inc.
        </p>
        <h2 id="copyrights.">12. Copyrights.</h2>
        <p>All Site design, text, graphics, the selection and arrangement thereof, Copyright ©, Yumi Inc. ALL RIGHTS RESERVED.</p>
        <h2 id="trademarks.">13. Trademarks.</h2>
        <p>
          YumiDocs, Yumi Inc., logos, all images and text, and all page headers, custom graphics and button icons are service marks,
          trademarks and the property of Yumi Inc. All other trademarks, product names and company names or logos cited herein are the
          property of their respective owners.
        </p>
        <h2 id="legal-age.">14. Legal age.</h2>
        <p>You agree that by using the YumiDocs you are at least 18 years of age and you are legally able to enter into a contract.</p>
        <h2 id="right-to-refuse.">15. Right to Refuse.</h2>
        <p>You acknowledge that YumiDocs reserves the right to refuse service to anyone and to cancel user access at any time.</p>
        <h2 id="acknowledgement.">16. Acknowledgement.</h2>
        <p>By using and accessing YumiDocs you acknowledge that you have read these terms of use and agree to be bound by them.</p>
        <h2 id="limits-on-customized-templates.">17. Limits on customized templates.</h2>
        <p>
          Some of our plans allow you to submit your own custom template documents that you or your attorney have created. We may be unable
          to process some of these documents due to technological limitations on our part, particularly if they contain complex internal
          logic or are in scanned PDF form. If we are unable to process your document, we will notify you and offer you the choice of an
          additional service you can add to your plan or alternative options.
        </p>
      </div>
    </section>
  )
}

export default TermsOfService
