import TickIcon from '../../Icons/Tick'
import './ContentAware.scss'
import Segment from 'common/SegmentIO'

const ContentAware = () => {
  const asideList = [
    'Create great contracts, fast',
    'Collaboratively negotiate terms',
    'Execute with Electronic Signature',
    'Renew/ amend/ terminate existing contracts',
    'Manage your contracts with data',
  ]

  return (
    <section className="ContentAware" id="ContentAware">
      <div className="content">
        <article>
          <h1>Create, sign, and manage<br/>better contracts, faster<br/>in one place</h1>
          <h2>Powerful, self-serve contract management to help you hire, sell more, and grow your business.</h2>
          {/* <h3>The complete solution for contracts that allows businesses to manage all their contracts in one place, reutilise their data and automate admin labour away.</h3> */}
          <div className="ContentAware__actions">
            <a
              href="https://app.yumidocs.com"
              target="_blank"
              className="btn dark"
              rel="noopener noreferrer"
              onClick={() => {
                Segment.getAnalytics().then((ana) => ana.track('Start Now', { section: 'ContentAware' }))
              }}
            >
              Start now
            </a>
          </div>
        </article>
        <aside>
          {/* <DiagramImage style={{minWidth:'300px', maxWidth:'600px', display:'inline'}}/> */}
          <ul>
            {asideList.map((item, key) => (
              <li key={key}>
                <TickIcon />
                {item}
              </li>
            ))}
          </ul>
        </aside>
      </div>
    </section>
  )
}

export default ContentAware
