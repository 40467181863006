import './Repapering.scss'

const CaseStudyAcq = () => {
  return (
    <section className="CaseStudyRepapering">
      <div className="CaseStudyRepapering__content">
        <h2>
          Acquisition Assistant repapers 314 contracts in <br />6 weeks.
        </h2>
        <br />
        <h3>
          YumiDocs enabled Acquisition Assistant to strategically negotiate contracts focusing on business terms first and legal fine print
          second.
        </h3>
        <table width="100%">
          <tbody>
            <tr className="linetop">
              <td className="headcol" width="40%">
                Number of contracts
              </td>
              <td width="60%">
                <span className="figure"> 314 contracts</span>
              </td>
            </tr>
            <tr className="linetop">
              <td className="headcol">Avg. contract cycle</td>
              <td>
                <span className="figure"> 6 working days</span>
              </td>
            </tr>
            <tr className="linetop">
              <td className="headcol">70% of total contracts signed</td>
              <td>
                <span className="figure"> within 9 working days</span>
              </td>
            </tr>
            <tr className="linetop">
              <td className="headcol">The longest cycle time</td>
              <td>
                <span className="figure">6 weeks</span>
              </td>
            </tr>
            <tr className="linetop">
              <td className="headcol">No signed without changes</td>
              <td>
                <span className="figure">72%</span>
              </td>
            </tr>
            <tr className="linetop">
              <td className="headcol">No of negotiated contracts</td>
              <td>
                <span className="figure">28%</span>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="company">
          <h2>About Acquisition Assistant</h2>
          <h2>
            <img src="/logos/ACQ.png" alt="Acquisition Assistant logo" width="60px" />
          </h2>
        </div>
        <p>
          <strong>Acquisition Assistant,</strong> a textile fabrics wholesaler has been operating across Europe for more than 15 years. Its
          clients are mostly based in the Europe and the UK. The goods sold include fabrics and linen with special properties used in
          chemical and industrial environments, PPE for doctors, firemen, toxic and other hazardous environments.
        </p>

        <div className="quote">
          <p>
            <span className="figure">“</span>We got a quote for repapering all our contracts from a law firm. It was a big commitment, and it
            was all manual work - ours, and theirs.<br />
            <br />
            Tech seemed a more viable route and YumiDocs team aced the repapering job. We also kept on using YumiDocs for its tidy
            end-to-end process. All our new clients have now a smooth and easy on-boarding experience.<span className="figure">”</span>
          </p>
          <div className="owner">Mihaela Talapanescu, Head of Sales</div>
        </div>

        <h2>The challenge</h2>
        <p>
          Acquisition Assistant, a high-quality textile wholesaler specialized for use in PPE for by doctors, firemen and industrial
          environments, has been operating for more than 12 years.
        </p>
        <p>
          As a result of Brexit, COVID and new regulatory changes regarding exports for PPE, the company had to update at least 300 master
          agreements with its clients to reflect the current operational, legal, and financial risks.
        </p>
        <p>
          The limited legal resources meant the legal department focused on the top business priority needs like reviewing contracts for new
          accounts. Historical contracts were almost never reviewed. When it happened the rest of the business was experiencing long delays
          as the legal department is actively needed in the daily operations.
        </p>
        <p>
          For the repapering exercise, the company simply didn't have the resources to review all historical contracts and apply a bespoke
          analysis to each relationship. A substantially more efficient approach was needed.
        </p>

        <h2>The solution</h2>
        <p>
          YumiDocs categorized  Acquisition Assistant's clients into 6 distinct business segments based on their jurisdiction, requested
          product types and operating model. Each category required different legal treatments, business responsibilities and liabilities.
          These were all factored into the new master agreement template as opt-in parameterized clauses.
        </p>
        <p>
          In addition to the agreement template, Acquisition Assistant created in an MS Excel spreadsheet the assignment between each client
          and the corresponding business segment with its specific terms.
        </p>
        <p>
          The updated master agreement template, the Excel spreadsheet and the CRM contact data was all it was needed to run the entire
          repapering exercise.
        </p>
        <h2>The results</h2>

        <p>
          314 new agreements were created and sent for signing to the corresponding counterparties in 2 days. The outreach was performed in
          batches of 50 accounts to control for potential errors.
        </p>
        <p>
          72% of the agreements were signed without any modifications or objections with the first one occurring 4 hours after being sent.
          The average signing time took 6 working days, with the longest taking 6 weeks due counterparty legal review and signatory
          availability.
        </p>

        <p>
          For the remainder of the 28% of contracts which needed changes, counterparties objections were mostly related to the business
          terms. The flexible approach YumiDocs takes to collaborative negotiations allowed the parties to easily add in additional services
          and alter the terms initially proposed on the outreach.
        </p>
        <p>
          The negotiation of business terms was a secondary objective to the exercise. For the 28% of the contracts when proper business
          negotiation was commenced, it worked to Acquisition Assistant’s advantage. Most contracts during the negotiation stage increased
          their contractual value by introducing supplementary services and benefits. YumiDocs enabled Acquisition Assistant to negotiate
          contracts from an operational business term perspective with the legal fine print and clauses following suit.
        </p>
        <p>The additional increase of annual contract value was an unexpected benefit that derived from the repapering exercise.</p>
        <ul>
          <li>
            <p>With YumiDocs, more than 70% of the total new service agreements are completed in less than 8 working days.</p>
          </li>
          <li>
            <p>
              The master agreement template improved iteratively, with clauses becoming more refined. Most clauses offer multiple to the
              counterparty.
            </p>
          </li>
          <li>
            <p>The automated part of the platform helps the team streamline their tasks through notifications, reminders, and updates.</p>
          </li>
          <li>
            <p>
              The status of each ongoing deal can be quickly checked from a centralized dashboard where contracts can be selected and
              filtered based on their on key contractual terms like expiration dates, party names, contract of indemnity provision type.
            </p>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default CaseStudyAcq
