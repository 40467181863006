import HeadlineTop from '../../common/HeadlineTop/HeadlineTop'
import ArrowRightIcon from '../../Icons/ArrowRight'

import './Principles.scss'

const Principles = () => {
  const list = [
    {
      title: 'Expertise over labor.',
      text: 'YumiDocs automates laborious and attention draining tasks so the experts can focus on core problems.',
    },
    {
      title: 'Maximum reusability.',
      text:'YumiDocs reutilizes all contractual data points on every occasion, even after the contract was executed. It\'s time to move beyond copy/pasting and text PDF files.'
    },
    {
      title: 'Methodology over ad-hoc processes.',
      text:
        'YumiDocs uses a clear and transparent workflow to bring clients, lawyers, and your team together to agree and sign contracts fast with minimum risk.',
    },
    {
      title: 'Focus on the End-To-End efficiency.',
      text: `Contract creation and signing is just as important as post-execution management (renewals, termination, amendments). Businesses never have to open PDF files to understand their terms and obligations.`,
    },
  ]

  return (
    <section className="Principles" id="Principles">
      <article>
        <div className="title">
          <HeadlineTop>Principles</HeadlineTop>
        </div>
        <ul className="annotation">
          {list.map((item, key) => (
            <li key={key}>
              <ArrowRightIcon />
              <div>
                <div className="annotation__label">{item.title}</div>
                <p>{item.text}</p>
              </div>
            </li>
          ))}
        </ul>
      </article>
    </section>
  )
}

export default Principles
