import './Workflow.scss'
import HeadlineTop from '../../common/HeadlineTop/HeadlineTop'
import { ReactComponent as Workflow } from '../../assets/YumiWorkflow.svg'

const Features = () => {
  return (
    <section className="Workflow" id="Workflow">
      <HeadlineTop>Methodology and Tools</HeadlineTop>
      <p className="description">Experience the one platform that makes contracts easy from start to finish. Everything is included.</p>
      <article>
        <Workflow />
      </article>
    </section>
  )
}

export default Features
