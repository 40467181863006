const Icon = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="31.262px"
    height="31.262px"
    viewBox="0 0 31.262 31.262"
    {...props}
  >
    <g>
      <path d="M21.445,15.412c-0.635-0.543-1.408-0.704-2.014-0.56c0.574-1.557,0.48-3.574-1.168-5.131
			c-1.578-1.489-3.403-0.51-4.857,0.197c-1.13,0.55-3.032-0.863-3.674,0.395c-0.281,0.554,0.398,1.452,0.501,1.582
			c0,0,0,0.004-0.003,0.007c0.013,0.012,0.023,0.022,0.031,0.031c-0.047,0.431-0.002,0.882,0.077,1.336
			c0.009,0.044,0.009,0.09,0.006,0.136c-0.254-0.11-0.505-0.306-0.707-0.632c0,0,0.102,0.481,0.694,0.727
			c-0.001,0.007-0.002,0.013-0.004,0.02c-0.339-0.081-0.695-0.282-0.965-0.718c0,0,0.177,0.55,0.954,0.761
			c-0.1,0.342-0.421,0.69-0.68,0.903c-0.233,0.192-0.67,0.305-0.413,0.646c0.192,0.254,0.657,0.156,0.757,0.564
			c0.051,0.207-0.426,0.605-0.316,0.771c0.042,0.108,0.27,0.218,0.357,0.246c-0.109,0.067-0.302,0.248-0.302,0.357
			c0.082,0.042,0.365,0.061,0.454,0.839c0.151,1.335,1.691,0.479,2.421,0.345c1.507-0.277,1.731,2.354,0.805,4.084l5.021-1.182
			c-0.121-0.673-0.879-2.964-1.385-3.893c0.488-0.037,0.99-0.331,1.43-0.793c0.129,0.596,0.543,1.195,1.08,1.498
			c0.877,0.494,1.48,1.406,1.504,2.763C22.332,18.074,22.455,16.275,21.445,15.412z" />
      <g>
        <path d="M15.285,5.535c0-1.728,0.348-3.127,0.348-3.127s0.347,1.399,0.347,3.127c0,0.365-0.018,0.711-0.043,1.037
				c0.476-0.548,1.344-1.734,1.344-3.121C17.281,1.545,15.634,0,15.634,0s-1.648,1.545-1.648,3.451c0,1.387,0.868,2.573,1.342,3.121
				C15.303,6.246,15.285,5.901,15.285,5.535z" />
        <path d="M15.979,25.727c0,1.729-0.347,3.127-0.347,3.127s-0.347-1.398-0.347-3.127c0-0.365,0.017-0.711,0.041-1.037
				c-0.475,0.549-1.342,1.734-1.342,3.121c0,1.906,1.647,3.451,1.647,3.451s1.648-1.545,1.648-3.451
				c0-1.387-0.868-2.572-1.344-3.121C15.961,25.016,15.979,25.361,15.979,25.727z" />
        <path d="M10.937,7.883c-0.117-0.172-0.236-0.355-0.352-0.557c-0.548-0.947-0.799-1.824-0.799-1.824s0.633,0.658,1.18,1.604
				c0.115,0.2,0.215,0.396,0.305,0.583c0.088-0.451,0.188-1.378-0.251-2.138c-0.604-1.046-1.997-1.372-1.997-1.372
				S8.606,5.549,9.21,6.594C9.649,7.356,10.502,7.733,10.937,7.883z" />
        <path d="M20.328,23.379c0.117,0.172,0.236,0.355,0.352,0.557c0.549,0.947,0.803,1.824,0.803,1.824s-0.637-0.656-1.184-1.604
				c-0.113-0.2-0.215-0.396-0.305-0.584c-0.088,0.453-0.188,1.379,0.252,2.138c0.605,1.047,1.998,1.373,1.998,1.373
				s0.414-1.37-0.189-2.415C21.615,23.906,20.764,23.529,20.328,23.379z" />
        <path d="M6.573,15.438c-0.349-0.301-1.101-0.85-1.978-0.851c-1.208,0.001-2.187,1.044-2.187,1.044s0.979,1.043,2.186,1.043
				c0.879,0,1.631-0.549,1.979-0.85C6.367,15.84,6.147,15.85,5.917,15.85c-1.094,0.001-1.98-0.22-1.98-0.22s0.887-0.222,1.98-0.222
				C6.148,15.41,6.365,15.422,6.573,15.438z" />
        <path d="M24.691,15.824c0.348,0.301,1.1,0.85,1.977,0.85c1.209,0,2.188-1.043,2.188-1.043s-0.979-1.043-2.188-1.043
				c-0.877,0-1.629,0.549-1.977,0.85c0.207-0.016,0.426-0.024,0.656-0.025c1.096,0,1.98,0.22,1.98,0.22s-0.887,0.222-1.98,0.22
				C25.115,15.852,24.898,15.84,24.691,15.824z" />
        <path d="M10.963,24.154c-0.545,0.946-1.18,1.604-1.18,1.604s0.252-0.878,0.799-1.825c0.117-0.2,0.236-0.385,0.354-0.555
				c-0.436,0.149-1.287,0.526-1.727,1.287c-0.604,1.046-0.189,2.415-0.189,2.415s1.393-0.326,1.997-1.37
				c0.438-0.761,0.339-1.688,0.253-2.14C11.181,23.758,11.081,23.953,10.963,24.154z" />
        <path d="M20.299,7.107c0.547-0.947,1.182-1.606,1.182-1.606S21.229,6.38,20.68,7.327c-0.113,0.201-0.232,0.385-0.352,0.556
				c0.436-0.15,1.287-0.527,1.725-1.287c0.605-1.045,0.191-2.416,0.191-2.416s-1.395,0.327-1.998,1.372
				c-0.439,0.761-0.34,1.686-0.252,2.139C20.084,7.502,20.186,7.307,20.299,7.107z" />
        <path d="M5.906,8.113C4.257,7.16,2.093,7.814,2.093,7.814s0.516,2.2,2.165,3.152c1.202,0.693,2.663,0.535,3.376,0.398
				c-0.295-0.141-0.604-0.3-0.92-0.482c-1.496-0.863-2.535-1.863-2.535-1.863s1.387,0.398,2.882,1.262
				c0.316,0.184,0.606,0.372,0.878,0.557C7.702,10.152,7.109,8.807,5.906,8.113z" />
        <path d="M27.004,20.295c-1.201-0.693-2.662-0.537-3.375-0.398c0.295,0.142,0.605,0.299,0.92,0.482
				c1.494,0.863,2.533,1.863,2.533,1.863s-1.385-0.398-2.881-1.262c-0.316-0.185-0.607-0.373-0.877-0.558
				c0.236,0.687,0.83,2.03,2.031,2.724c1.65,0.952,3.812,0.301,3.812,0.301S28.652,21.247,27.004,20.295z" />
        <path d="M4.18,22.242c0,0,1.039-1.002,2.534-1.865c0.317-0.184,0.626-0.34,0.921-0.482C6.922,19.758,5.46,19.6,4.26,20.294
				c-1.651,0.952-2.166,3.151-2.166,3.151s2.162,0.653,3.813-0.299c1.201-0.693,1.795-2.039,2.032-2.724
				c-0.271,0.185-0.562,0.371-0.876,0.556C5.567,21.842,4.18,22.242,4.18,22.242z" />
        <path d="M27.082,9.02c0,0-1.037,1-2.533,1.863c-0.314,0.183-0.625,0.342-0.92,0.482c0.713,0.138,2.174,0.295,3.373-0.398
				c1.652-0.953,2.166-3.152,2.166-3.152s-2.16-0.652-3.811,0.299c-1.203,0.693-1.797,2.037-2.033,2.723
				c0.27-0.183,0.561-0.371,0.879-0.555C25.697,9.418,27.082,9.02,27.082,9.02z" />
      </g>
    </g>
  </svg>
)

export default Icon
