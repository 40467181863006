import './Benefits.scss'
import HeadlineTop from '../../common/HeadlineTop/HeadlineTop'
import CollapsibleBenefit from './CollapsibleBenefit'
import BalanceIcon from '../../Icons/Balance'
import CalendarIcon from '../../Icons/Calendar'
import Business from '../../Icons/Business'
import IndependentIcon from '../../Icons/Independent'
import TemplateIcon from '../../Icons/Template'
import ESigIcon from '../../Icons/ESig'
import NetworkIcon from '../../Icons/Network'
import HandOverIcon from '../../Icons/HandOver'

const Benefits = () => {
  const list = [
    {
      headline: 'Use standardized, balanced templates that are 70% more likely to get accepted',
      description: [
        <p>
          Our standardized templates are balanced, fair and easy to read. Some have been created by large committees of lawyers that checked
          them for business flexibility and fairness.
        </p>,
        <p>Get started with SaaS agreement or a Mutual NDAs by the Bonterms committee.</p>,
      ],
      logo: <BalanceIcon width="40" height="40" fill="green" />,
    },
    {
      headline: 'Stay on top of expiration dates, automatically',
      description: [
        <p key="1">Stay on top of expiration terms, autorenewals and key terms without opening PDFs or manually maintaining terms.</p>,
      ],
      logo: <CalendarIcon width="40" height="40" fill="green" />,
    },
    {
      headline: 'Manage business and legal terms, not PDF files',
      description: [
        <p>
          Manage your contracts by using the legal and business terms of each contract: expiration dates, service fees, indemnity terms,
          jourisdiction and party names.
        </p>,
        <p>No more chasing and opening PDF files to be reminded of the expiration dates.</p>,
      ],
      logo: <Business width="40" height="40" fill="green" />,
    },
    {
      headline: 'Make your own agreements reusable, easy to read, use and accept.',
      description: [
        <p>Standardised means easier to fill in, easier to read, capture more data and get agreed faster with less objections.</p>,
        <p>
          We can teach you how to turn your own bespoke contracts into standardised termplates that are fast to use by your teams and easy
          to accept by your clients.
        </p>,
      ],
      logo: <TemplateIcon width="40" height="40" fill="green" />,
    },
    {
      headline: 'Make your team independent',
      description: [
        <p>
          <strong>More than 75% of all SME contracts</strong> are using standard business and legal terms.
        </p>,
        <p>
          Allow your team to self-service its recurrent, daily needs with company's approved templates and playbooks. You legal team can
          focus now on the game-changing deals.
        </p>,
      ],
      logo: <IndependentIcon width="40" height="40" fill="green" />,
    },
    {
      headline: 'All your contracts in one place',
      description: [
        <p>All your signed agreements, on-going negotiations, drafts, templates and playbooks are managed in one place.</p>,
        <p>Everyone knows where to find all contracts and how to draft new company compliant agreements without mistakes.</p>,
      ],
      logo: <NetworkIcon width="40" height="40" fill="green" />,
    },
    {
      headline: 'E-Signature is included',
      description: [
        <p>YumiDocs E-signature capabilities are compliant with EIDAS, UETA, ESIGN Act and are recognized by most legal jourisdictions.</p>,
        <p>
          All YumiDocs agreements can be executed offline on user's device using Qualified Electronic Signatures (QES). Most EU companies
          already have QES devices.
        </p>,
      ],
      logo: <ESigIcon width="40" height="40" fill="green" />,
    },
    {
      headline: 'Your counterparties get YumiDocs for free',
      description: [
        <p>
          For all contracts, your counterparties get access to YumiDocs for free to speed up their contract review, negotiation and signing
          process.{' '}
        </p>,
        <p>No Word and PDF files via email, tracked changes and chaser emails needed.</p>,
      ],
      logo: <HandOverIcon width="40" height="40" fill="green" />,
    },
  ]

  let columns = list.reduce(
    (buf, el, idx) => {
      if (idx % 2 == 0) {
        buf.left.push(el)
      } else {
        buf.right.push(el)
      }
      return buf
    },
    { left: [], right: [] }
  )

  return (
    <section className="Benefits" id="Benefits" key="benefits">
      <HeadlineTop>Built for ambitious businesses</HeadlineTop>

      <article className="Benefits__items">
        <div>{columns.left.map((q, key) => <CollapsibleBenefit key={key} benefit={q} />)}</div>
        <div>{columns.right.map((q, key) => <CollapsibleBenefit key={key} benefit={q} />)}</div>
      </article>
    </section>
  )
}

export default Benefits
