import ContentAware from './ContentAware/ContentAware'
import ToolsForGrowth from './ToolsForGrowth/ToolsForGrowth'
import Benefits2 from './Benefits2/Benefits'
import GetEfficiency from './GetEfficiency/GetEfficiency'
import TemplateLibrary from './TemplateLibrary/TemplateLibrary'
import Features2 from './Features2/Features2'
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy'
import TermsOfService from './TermsOfService/TermsOfService'
import Security from './Security/Security'
import Workflow from '../Sections/Workflow/Workflow'
import OurProposal from '../Sections/OurProposal/OurProposal'
import ProblemPitch from '../Sections/ProblemPitch/ProblemPitch'
import Logos from './Logos/Logos'
import Stats from './Stats/Stats'
import PlatformPage from '../Pages/Platform/Platform'
import PricingPage from '../Pages/Pricing/Pricing'
import CaseStudyBoxifier from './CaseStudy1-Boxifier/CaseStudy1'
import CaseStudyRepapering from './CaseStudy2-AcquisitionAssistant/Repapering'

const sections = [
  {
    path: '/',
    label: 'Home',
    children: [
      ContentAware,
      Stats,
      Logos,
      ProblemPitch,
      Workflow,
      Benefits2,
      ToolsForGrowth,      
      OurProposal,
      TemplateLibrary,
      GetEfficiency,
      Features2
    ],
  },

  {
    path: '/platform',
    label: 'Platform',
    children: [ PlatformPage ],
  },
  {
    path: '/pricing',
    label: 'Pricing',
    children: [ PricingPage ],
  },
  {
    path: '/privacy-policy',
    label: 'Privacy Policy',
    children: [ PrivacyPolicy ],
  },
  {
    path: '/terms-of-service',
    label: 'Terms of Service',
    children: [ TermsOfService ],
  },
  {
    path: '/security',
    label: 'YumiDocs Security',
    children: [ Security ],
  },
  {
    path: '/casestudy/accelerated-sales',
    label: 'Case Studies - Boxifier - Accelerated sales cycle',
    children: [ CaseStudyBoxifier ],
  },
  {
    path: '/casestudy/repapering',
    label: 'Case Studies - Acquisition Assistant - Repapering',
    children: [ CaseStudyRepapering ],
  },
]
export default sections
