import { AnalyticsBrowser } from '@segment/analytics-next'

class Segment {
  static analytics = null

  static async getAnalytics() {
    if (window.location.host.includes('local')) return null
    if (Segment.analytics == null) {
      return AnalyticsBrowser.load(
        {
          writeKey: 'nRoK4cWCoVHXpQY93Sk3FrmnhIXGbaFT',
          cdnURL: 'https://cdn.a.yumidocs.com',
        },
        {
          integrations: {
            'Segment.io': {
              apiHost: 'api.a.yumidocs.com/v1',
            },
          },
        }
      ).then((res) => {
        Segment.analytics = res[0]
        return Segment.analytics
      })
    } else return Promise.resolve(Segment.analytics)
  }
}

export default Segment
