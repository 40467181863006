import './Stats.scss'

const Stats = () => {
  const stats = [
    {
      info: 'needed to draft and send standard terms',
      quote: '4 mins',
    },{
      info: ['faster',<br/>,'contract cycle time'],
      quote: '70%',
    },
    {
      info: 'less mistakes',
      quote: '95%',
    },
    {
      quote: '100%',
      info: ['correct',<br/>,'contractual terms'],      
    },
  ]

  return (
    <section className="Stats" id="Stats">
      <article className="Stats__item">
        {stats.map(({ quote, info, iconUrl }, key) => (
          <div key={key} className="Stats__item__card">
            {/* <div className="Stats__item__card__imageContainer">{iconUrl}</div> */}
            <div className="Stats__item__card__quote">{quote}</div>
            <div className="Stats__item__card__info">{info}</div>
          </div>
        ))}
      </article>
    </section>
  )
}

export default Stats
